import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reset, change } from "redux-form";

import PageNoTitle from "@components/PageNoTitle";
import { adaptOptions, dateToString, formatNumber, formatDateDefault, stringToDate, openUrl } from "../../utils";
import {
  PRICE_TYPES,
  STATUSES,
  STATUSES_FILTER,
  DATE_FORMAT_DATE_PICKER,
  DATE_FORMAT_INPUT,
  YES_NO_FILTER
} from "@constants";
import AllIpfList from "./components/AllIpfList";
import MyIpfList from "./components/MyIpfList";
import UserAccessRequestList from "./components/UserAccessRequestList";
import {
  allIpfList,
  allIpfCurrentPage,
  specificCommodityList,
  countryZoneList,
  myIpfList,
  myIpfCurrentPage,
  userSearchDefaultDetail,
  userSearchDefaultAdd,
  userSearchDefaultDetailReset,
  showAllIpfFiltersArea,
  hideAllIpfFiltersArea,
  accessRequestList,
  accessRequestsCurrentPage
} from "@actions";

const API_URL_DOWNLOAD_XLS_ALL_IPFS = `${process.env.API_BASE_URL}/api/purchase_requests/xls/`;

// A function to adapt preset received from server to be used by redux form
export const adaptInitialFilters = initialFilters => ({
  ...initialFilters,
  dates: {
    startDate:
      initialFilters.dates &&
      initialFilters.dates.startDate &&
      stringToDate(initialFilters.dates.startDate, DATE_FORMAT_DATE_PICKER),
    endDate:
      initialFilters.dates &&
      initialFilters.dates.endDate &&
      stringToDate(initialFilters.dates.endDate, DATE_FORMAT_DATE_PICKER)
  }
});
// A function to find the right source of supply for an IPF starting from the chosen purchase option
export const findSourceOfSupply = purchaseOptions => {
  if (purchaseOptions && purchaseOptions.length) {
    const preferred = purchaseOptions.find(purchaseOption => purchaseOption.preferred);
    if (preferred) {
      return PRICE_TYPES.find(source => preferred.source_of_supply === source.value);
    }
  }
  return { value: "", description: "-" };
};

// A function to find the right source of supply for an IPF starting from the chosen purchase option
export const findValue = purchaseOptions => {
  if (purchaseOptions && purchaseOptions.length) {
    const preferred = purchaseOptions.find(purchaseOption => purchaseOption.preferred);
    if (preferred) {
      return formatNumber(preferred.total_costs);
    }
  }
  return "-";
};

// A function to adapt IPFs to a custom format
export const adaptIpfs = ipfs =>
  ipfs.map(ipf => ({
    ...ipf,
    value: findValue(ipf.purchase_options),
    source: findSourceOfSupply(ipf.purchase_options),
    status: STATUSES.find(status => ipf.state === status.value) || { value: "", description: "-" },
    quantity: formatNumber(ipf.quantity),
    submission_date: formatDateDefault(ipf.submission_date),
    additional_information: {
      project_cpb: ipf.project && ipf.project.title,
      wbs_code: ipf.wbs && ipf.wbs.code
    }
  }));

// The main page of the application
export class Home extends Component {
  // On component mount, IPFs, my IPFs and related entities are loaded
  componentDidMount() {
    this.onPageChangeAllIpfs = this.onPageChangeAllIpfs.bind(this);
    this.onPageChangeMyIpfs = this.onPageChangeMyIpfs.bind(this);
    this.onPageChangeAccessRequests = this.onPageChangeAccessRequests.bind(this);
    this.onClickSearch = this.onClickSearch.bind(this);
    this.onClickDetail = this.onClickDetail.bind(this);
    this.onClickResetFiltersForm = this.onClickResetFiltersForm.bind(this);
    this.onClickResetPreset = this.onClickResetPreset.bind(this);
    this.onClickShowHideAllIpfFiltersArea = this.onClickShowHideAllIpfFiltersArea.bind(this);
    this.onClickExportAllIpfs = this.onClickExportAllIpfs.bind(this);
    this.onClickUserRequestDetail = this.onClickUserRequestDetail.bind(this);
    this.filterAccessRequests(0);
    this.props.specificCommodityList();
    this.props.countryZoneList();
    this.props.userSearchDefaultDetail().finally(() => this.filterAllIpfsInitial());
    this.filterMyIpfs(0);
    this.props.showAllIpfFiltersArea();
  }

  // On component unmount store is reset where needed
  componentWillUnmount() {
    this.props.allIpfCurrentPage(0);
    this.props.myIpfCurrentPage(0);
    this.props.accessRequestsCurrentPage(0);
    this.props.userSearchDefaultDetailReset();
  }

  // A method to filter by props and and given filter
  filterAllIpfsInitial() {
    const {
      presetCountriesZones,
      presetCommodities,
      presetStatus,
      presetAboveIpp,
      presetSmallholderFarmer,
      presetFromDate,
      presetToDate,
      presetIpfId
    } = this.props;
    this.props.allIpfCurrentPage(0);

    this.props.allIpfList(
      1,
      presetCountriesZones,
      presetCommodities,
      presetStatus,
      presetAboveIpp,
      presetSmallholderFarmer,
      presetFromDate,
      presetToDate,
      presetIpfId
    );
  }

  // A method to filter by props and and given filter
  filterAllIpfs(page) {
    const {
      selectedCountriesZones,
      selectedCommodities,
      selectedStatus,
      aboveIpp,
      smallholderFarmer,
      fromDate,
      toDate,
      ipfId
    } = this.props;
    this.props.allIpfCurrentPage(page);
    this.props.allIpfList(
      page + 1,
      selectedCountriesZones,
      selectedCommodities,
      selectedStatus,
      aboveIpp,
      smallholderFarmer,
      fromDate,
      toDate,
      ipfId
    );
  }

  // A method to filter by props and and given filter
  filterMyIpfs(page) {
    this.props.myIpfCurrentPage(page);
    this.props.myIpfList(page + 1);
  }

  filterAccessRequests(page) {
    this.props.accessRequestsCurrentPage(page);
    this.props.accessRequestList(page + 1);
  }

  // When user select a page we have to perform a request with given parameters
  onPageChangeAllIpfs(page) {
    this.filterAllIpfs(page);
  }

  onPageChangeAccessRequests(page) {
    this.filterAccessRequests(page);
  }

  // When user select a page we have to perform a request with given parameters
  onPageChangeMyIpfs(page) {
    this.filterMyIpfs(page);
  }

  // When user click search, IPFs will be filtered and the preset is saved, if user checked
  // the checkbox is set to false after the reset
  onClickSearch() {
    this.filterAllIpfs(0);
    if (this.props.shouldSavePreset) {
      this.props.clearSavePreset();
      this.props.userSearchDefaultAdd(this.props.filterFormValues);
    }
  }

  // When user click reset preset, filters are reset backend side,
  // the checkbox is set to false after the reset,
  // defaults should be deleted from redux store to clear form initial values
  // and, after that, the form should be reset to initial values
  onClickResetPreset() {
    this.props.userSearchDefaultAdd({});
    this.props.userSearchDefaultDetailReset();
    this.props.resetFiltersForm();
  }

  // When user clicks view button it is redirected to the detail page
  // or to edit page if the status is draft
  // note that, if user has click on view button in All IPF list
  // he is redirected to detail
  onClickDetail(id) {
    const ipf = this.props.myIpfs.find(ipf => ipf.id === id);
    const statuses = ["draft", "draft_endorsed", "draft_cleared"];
    if (ipf && statuses.indexOf(ipf.status.value) !== -1) {
      this.props.history.push(`/IPFDetail/${id}`);
    } else {
      this.props.history.push(`/IPFView/${id}`);
    }
  }

  onClickUserRequestDetail(id) {
    const request = this.props.accessRequests.find(request => request.id === id);
    const nextUrl = request.state === "draft" ? `/userAccess/${id}` : `/accessRequestView/${id}`;
    this.props.history.push(nextUrl);
  }

  // On click reset defaults should be deleted from redux store to clear form initial values
  // and, after that, the form should be reset to initial values
  onClickResetFiltersForm() {
    this.props.userSearchDefaultDetailReset();
    this.props.resetFiltersForm();
  }

  // When user clicks show/hide all IPF filters the area is showed/hidden
  onClickShowHideAllIpfFiltersArea() {
    if (this.props.isVisibleAllIpfFiltersArea) {
      this.props.hideAllIpfFiltersArea();
    } else {
      this.props.showAllIpfFiltersArea();
    }
  }

  // Data are exported as Excel file based on filters selected by user
  onClickExportAllIpfs() {
    let parameters = [];
    parameters.push({ label: "country", value: this.props.selectedCountriesZones });
    parameters.push({ label: "commodity", value: this.props.selectedCommodities });
    parameters.push({ label: "status", value: this.props.selectedStatus });
    parameters.push({ label: "submission_date_from", value: this.props.fromDate });
    parameters.push({ label: "submission_date_to", value: this.props.toDate });
    parameters.push({ label: "ipf_id", value: this.props.ipfId });
    openUrl(API_URL_DOWNLOAD_XLS_ALL_IPFS, parameters);
  }

  render() {
    const {
      isFetchingAllIpfs,
      isFetchingMyIpfs,
      isFetchingFilters,
      allIpfs,
      myIpfs,
      errorMessageAllIpfs,
      errorMessageMyIpfs,
      errorMessageFilters,
      allIpfsTotalItems,
      myIpfsTotalItems,
      allIpfsPage,
      myIpfsPage,
      specificCommodities,
      countriesZones,
      statuses,
      aboveIppOptions,
      smallholderFarmerOptions,
      isLoadingPresets,
      initialFilters,
      isVisibleAllIpfFiltersArea,
      isFetchingAccessRequests,
      errorMessageAccessRequests,
      accessRequests,
      accessRequestsPage,
    } = this.props;
    const activeAccessRequests = accessRequests.filter((request) => ["completed", "cancelled"].indexOf(request.state) === -1);
    const anyMyIpf = myIpfs && myIpfs.length > 0;
    return (
      <PageNoTitle>
        {accessRequests && activeAccessRequests.length > 0 ? (
          <UserAccessRequestList
            loadingAccessRequests={isFetchingAccessRequests}
            errorMessageAccessRequests={errorMessageAccessRequests}
            accessRequests={activeAccessRequests}
            totalItems={activeAccessRequests.length}
            page={accessRequestsPage}
            onPageChange={this.onPageChangeAccessRequests}
            onClickDetail={this.onClickUserRequestDetail}
          />
        ) : (
          <></>
        )}
        {anyMyIpf ? (
          <MyIpfList
            loadingIpfs={isFetchingMyIpfs}
            errorMessageIpfs={errorMessageMyIpfs}
            ipfs={myIpfs}
            totalItems={myIpfsTotalItems}
            page={myIpfsPage}
            onPageChange={this.onPageChangeMyIpfs}
            onClickDetail={this.onClickDetail}
            onClickExport={this.onClickExportMyIpfs}
          />
        ) : (
          <></>
        )}
        <AllIpfList
          loadingIpfs={isFetchingAllIpfs}
          loadingFilters={isFetchingFilters}
          errorMessageIpfs={errorMessageAllIpfs}
          errorMessageFilters={errorMessageFilters}
          ipfs={allIpfs}
          totalItems={allIpfsTotalItems}
          page={allIpfsPage}
          onPageChange={this.onPageChangeAllIpfs}
          onClickSearch={this.onClickSearch}
          onClickDetail={this.onClickDetail}
          specificCommodities={specificCommodities}
          countriesZones={countriesZones}
          statuses={statuses}
          aboveIpp={aboveIppOptions}
          smallholderFarmer={smallholderFarmerOptions}
          onClickReset={this.onClickResetFiltersForm}
          initialValues={initialFilters}
          onClickResetPreset={this.onClickResetPreset}
          loadingPresets={isLoadingPresets}
          onClickShowHideFiltersArea={this.onClickShowHideAllIpfFiltersArea}
          isVisibleFiltersArea={isVisibleAllIpfFiltersArea}
          onClickExport={this.onClickExportAllIpfs}
        />
      </PageNoTitle>
    );
  }
}

// propTypes for the Home component
Home.propTypes = {
  isFetchingAllIpfs: PropTypes.bool.isRequired,
  isFetchingFilters: PropTypes.bool.isRequired,
  allIpfs: PropTypes.array.isRequired,
  allIpfList: PropTypes.func.isRequired,
  errorMessageAllIpfs: PropTypes.string.isRequired,
  errorMessageFilters: PropTypes.string.isRequired,
  allIpfsTotalItems: PropTypes.number.isRequired,
  allIpfsPage: PropTypes.number.isRequired,
  allIpfCurrentPage: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }),
  specificCommodities: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired
    })
  ),
  countriesZones: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired
    })
  ),
  statuses: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired
    })
  ),
  aboveIppOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired
    })
  ),
  smallholderFarmerOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired
    })
  ),
  specificCommodityList: PropTypes.func.isRequired,
  countryZoneList: PropTypes.func.isRequired,
  selectedCommodities: PropTypes.array.isRequired,
  selectedCountriesZones: PropTypes.array.isRequired,
  selectedStatus: PropTypes.array.isRequired,
  aboveIpp: PropTypes.oneOf(["True", "False", null]),
  smallholderFarmer: PropTypes.oneOf(["True", "False", null]),
  fromDate: PropTypes.string.isRequired,
  toDate: PropTypes.string.isRequired,
  ipfId: PropTypes.string.isRequired,
  myIpfCurrentPage: PropTypes.func.isRequired,
  myIpfList: PropTypes.func.isRequired,
  isFetchingMyIpfs: PropTypes.bool.isRequired,
  myIpfs: PropTypes.array.isRequired,
  errorMessageMyIpfs: PropTypes.string.isRequired,
  myIpfsTotalItems: PropTypes.number.isRequired,
  myIpfsPage: PropTypes.number.isRequired,
  userSearchDefaultDetail: PropTypes.func.isRequired,
  userSearchDefaultDetailReset: PropTypes.func.isRequired,
  presetAboveIpp: PropTypes.oneOf(["True", "False", "", null]),
  presetSmallholderFarmer: PropTypes.oneOf(["True", "False", "", null]),
  presetCommodities: PropTypes.array.isRequired,
  presetCountriesZones: PropTypes.array.isRequired,
  presetStatus: PropTypes.array.isRequired,
  presetFromDate: PropTypes.string.isRequired,
  presetToDate: PropTypes.string.isRequired,
  presetIpfId: PropTypes.string.isRequired,
  shouldSavePreset: PropTypes.bool.isRequired,
  clearSavePreset: PropTypes.func.isRequired,
  userSearchDefaultAdd: PropTypes.func.isRequired,
  resetFiltersForm: PropTypes.func.isRequired,
  isLoadingPresets: PropTypes.bool.isRequired,
  initialFilters: PropTypes.object.isRequired,
  filterFormValues: PropTypes.any.isRequired,
  isVisibleAllIpfFiltersArea: PropTypes.bool.isRequired,
  hideAllIpfFiltersArea: PropTypes.func.isRequired,
  showAllIpfFiltersArea: PropTypes.func.isRequired,
  isFetchingAccessRequests: PropTypes.bool.isRequired,
  errorMessageAccessRequests: PropTypes.string.isRequired,
  accessRequestList: PropTypes.func.isRequired,
  accessRequests: PropTypes.array.isRequired,
  accessRequestsPage: PropTypes.number.isRequired,
  accessRequestsCurrentPage: PropTypes.func.isRequired,
  accessRequestsTotalItems: PropTypes.number.isRequired
};

// Starting from the redux state it gets data related to IPF list
export const mapStateToProps = state => {
  const {
    form,
    userSearchDefaultDetail,
    userSearchDefaultAdd,
    specificCommodityList,
    countryZoneList,
    allIpfList,
    allIpfCurrentPage,
    allIpfFiltersArea,
    myIpfList,
    myIpfCurrentPage,
    accessRequestList,
    accessRequestsCurrentPage
  } = state;
  const { homeFilters } = form;

  // A funcion to create an array of applied filters
  const createFiltersArray = (filters, property) => {
    const hasProperty = filters && filters.values && filters.values[property];
    let filterArray = [];
    if (hasProperty) filterArray = filters.values[property].map(element => element.value);
    return filterArray;
  };
  //A function that sets search preference
  const setPreferences = (filters, property, defaultValue) => {
    const hasProperty = filters && filters.data && filters.data.preferences && filters.data.preferences[property];
    let preferences = defaultValue;
    if (hasProperty) {
      const preferenceProp = filters.data.preferences[property];
      if (Array.isArray(preferenceProp)) {
        preferences = preferenceProp.map(element => element.value);
      } else if (typeof preferenceProp === "string") {
        preferences = preferenceProp;
      } else if (typeof preferenceProp === "object") {
        preferences = preferenceProp.data && preferenceProp.data.value;
      }
    }
    return preferences;
  };

  const setDatePreferences = (filters, property, defaultValue) => {
    const hasProperty =
      filters &&
      filters.data &&
      filters.data.preferences &&
      filters.data.preferences.dates &&
      filters.data.preferences.dates[property];
    let preferences = defaultValue;
    if (hasProperty) {
      const preferenceProp = filters.data.preferences.dates[property];
      preferences = dateToString(preferenceProp, DATE_FORMAT_DATE_PICKER, DATE_FORMAT_INPUT);
    }
    return preferences;
  };

  return {
    //User access requests properties
    isFetchingAccessRequests: accessRequestList.isFetching,
    errorMessageAccessRequests: accessRequestList.errorMessage,
    accessRequests: accessRequestList.data.results,
    accessRequestsPage: accessRequestsCurrentPage.number,
    accessRequestsTotalItems: accessRequestList.data.count || 0,
    // Related entities properties
    specificCommodities: adaptOptions(specificCommodityList.data.results, "code", "description"),
    countriesZones: adaptOptions(countryZoneList.data.results, "iso_code", "name"),
    isFetchingFilters: specificCommodityList.isFetching || countryZoneList.isFetching,
    errorMessageFilters: specificCommodityList.errorMessage || countryZoneList.errorMessage,
    statuses: [{ value: "", label: "All" }, ...adaptOptions(STATUSES_FILTER, "value", "description", undefined, true)],
    aboveIppOptions: adaptOptions(YES_NO_FILTER, "value", "label", undefined, true),
    smallholderFarmerOptions: adaptOptions(YES_NO_FILTER, "value", "label", undefined, true),
    // // All IPSs properties
    allIpfs: adaptIpfs(allIpfList.data.results),
    isFetchingAllIpfs: allIpfList.isFetching,
    errorMessageAllIpfs: allIpfList.errorMessage,
    // My IPSs properties
    myIpfs: adaptIpfs(myIpfList.data.results),
    isFetchingMyIpfs: myIpfList.isFetching,
    errorMessageMyIpfs: myIpfList.errorMessage,
    // Table management of all IPFs list
    allIpfsPage: allIpfCurrentPage.number,
    allIpfsTotalItems: allIpfList.data.count || 0,
    // Table management of my IPFs list
    myIpfsPage: myIpfCurrentPage.number,
    myIpfsTotalItems: myIpfList.data.count || 0,
    // Selected filters management and initial values
    // statuses have an array of values associated
    // if user selects "rejected", he will filter for more than one status
    // as defined in STATUSES_FILTER
    isVisibleAllIpfFiltersArea: allIpfFiltersArea.isVisible,
    filterFormValues: (form && homeFilters && { ...homeFilters.values, savePreset: false }) || {},
    aboveIpp:
      (homeFilters && homeFilters.values && homeFilters.values.aboveIpp && homeFilters.values.aboveIpp.value) || null,
    smallholderFarmer:
      (homeFilters &&
        homeFilters.values &&
        homeFilters.values.smallholderFarmer &&
        homeFilters.values.smallholderFarmer.value) ||
      null,
    selectedCountriesZones: createFiltersArray(homeFilters, "countriesZones"),
    selectedCommodities: createFiltersArray(homeFilters, "commodities"),
    selectedStatus:
      (homeFilters &&
        homeFilters.values &&
        homeFilters.values.status &&
        homeFilters.values.status.data &&
        homeFilters.values.status.data.codes) ||
      [],
    fromDate:
      (homeFilters &&
        homeFilters.values &&
        homeFilters.values.dates &&
        dateToString(homeFilters.values.dates.startDate, DATE_FORMAT_DATE_PICKER, DATE_FORMAT_INPUT)) ||
      "",
    toDate:
      (homeFilters &&
        homeFilters.values &&
        homeFilters.values.dates &&
        dateToString(homeFilters.values.dates.endDate, DATE_FORMAT_DATE_PICKER, DATE_FORMAT_INPUT)) ||
      "",
    ipfId: (homeFilters && homeFilters.values && homeFilters.values.ipfId) || "",
    // Save preset options, filters form values and user default settings
    shouldSavePreset: (form && homeFilters && homeFilters.values && homeFilters.values.savePreset) || false,
    isLoadingPresets: userSearchDefaultDetail.isFetching || userSearchDefaultAdd.isLoading,
    initialFilters:
      (userSearchDefaultDetail &&
        userSearchDefaultDetail.data &&
        userSearchDefaultDetail.data.preferences &&
        adaptInitialFilters(userSearchDefaultDetail.data.preferences)) ||
      {},
    presetCommodities: setPreferences(userSearchDefaultDetail, "commodities", []),
    presetCountriesZones: setPreferences(userSearchDefaultDetail, "countriesZones", []),
    presetAboveIpp: setPreferences(userSearchDefaultDetail, "aboveIpp", null),
    presetSmallholderFarmer: setPreferences(userSearchDefaultDetail, "smallholderFarmer", null),
    presetStatus:
      (userSearchDefaultDetail &&
        userSearchDefaultDetail.data &&
        userSearchDefaultDetail.data.preferences &&
        userSearchDefaultDetail.data.preferences.status &&
        userSearchDefaultDetail.data.preferences.status.data &&
        userSearchDefaultDetail.data.preferences.status.data.codes) ||
      [],
    presetFromDate: setDatePreferences(userSearchDefaultDetail, "startDate", ""),
    presetToDate: setDatePreferences(userSearchDefaultDetail, "endDate", ""),
    presetIpfId: setPreferences(userSearchDefaultDetail, "ipfId", "")
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = dispatch => {
  return {
    allIpfList: (
      page,
      country,
      commodity,
      status,
      above_ipp,
      small_holder_farmer,
      submission_date_from,
      submission_date_to,
      ipf_id
    ) =>
      dispatch(
        allIpfList({
          page,
          country,
          commodity,
          status,
          above_ipp,
          small_holder_farmer,
          submission_date_from,
          submission_date_to,
          ipf_id
        })
      ),
    accessRequestList: page => dispatch(accessRequestList({ page, page_size: 5 }, "related_requests/")),
    accessRequestsCurrentPage: number => dispatch(accessRequestsCurrentPage(number)),
    myIpfList: page => dispatch(myIpfList({page})),
    allIpfCurrentPage: number => dispatch(allIpfCurrentPage(number)),
    myIpfCurrentPage: number => dispatch(myIpfCurrentPage(number)),
    specificCommodityList: () => dispatch(specificCommodityList()),
    countryZoneList: () => dispatch(countryZoneList()),
    resetFiltersForm: () => dispatch(reset("homeFilters")),
    clearSavePreset: () => dispatch(change("homeFilters", "savePreset", false)),
    userSearchDefaultDetail: () => dispatch(userSearchDefaultDetail("ipf")),
    userSearchDefaultAdd: data => dispatch(userSearchDefaultAdd({ search_type: "ipf", preferences: data })),
    userSearchDefaultDetailReset: () => dispatch(userSearchDefaultDetailReset()),
    showAllIpfFiltersArea: () => dispatch(showAllIpfFiltersArea()),
    hideAllIpfFiltersArea: () => dispatch(hideAllIpfFiltersArea())
  };
};

// The form is added to the redux store with a given name
export default connect(mapStateToProps, mapDispatchToProps)(Home);
