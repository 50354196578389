import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { change, reset } from "redux-form";
import moment from "moment";

import PageStandard from "../../components/PageStandard";
import ModalSuccess from "../../components/ModalSuccess";
import { Grid, Row, Col } from "../../components/Grid";
import StepNavigation from "../../components/StepNavigation";
import CriteriaStandard from "./components/CriteriaStandard";
import CriteriaGcmf from "./components/CriteriaGcmf";
import CompareSources from "./components/CompareSources";
import ReviewCostsAndTimes from "./components/ReviewCostsAndTimes";
import SelectSource from "./components/SelectSource";
import Justification from "./components/Justification";
import DestinationAndRtaStandard from "./components/DestinationAndRtaStandard";
import DestinationAndRtaGcmf from "./components/DestinationAndRtaGcmf";
import SelectedDataStandard from "./components/SelectedDataStandard";
import SelectedDataGcmf from "./components/SelectedDataGcmf";
import SummaryStandard from "./components/SummaryStandard";
import SummaryGcmf from "./components/SummaryGcmf";
import Loading from "../../components/Loading";
import Axios from "axios";
import {
  adaptOptions,
  validateData,
  isEmpty,
  clearNumber,
  formatNumber,
  dateToString,
  stringOrDash,
  stringToDate,
  isMinor
} from "../../utils";
import { hasPermission } from "../../utils/permissions";
import { adaptCommodityPrices } from "../../utils/commodityPrice";
import {
  ipfDetailCurrentTab,
  countryList,
  wbsList,
  wbsListReset,
  specificCommodityList,
  projectList,
  ipfDetailCriteriaStandardErrors,
  ipfDetailCriteriaGcmfErrors,
  grantList,
  ipfDetailCommodityPriceList,
  ipfDetailDestinationRtaStandardErrors,
  dischargePointList,
  deliveryPointList,
  ipfDetailCompereSourcesErrors,
  projectListReset,
  deliveryPointListReset,
  dischargePointDefaultList,
  ipfDetailCommodityPriceOptions,
  ipfDetailCommodityPriceOptionsReset,
  ipfDetailSelectSourceErrors,
  ipfDetailJustificationErrors,
  ipfAdd,
  ipfAddReset,
  showSuccessIpfAddModal,
  hideSuccessIpfAddModal,
  ipfDetailCommodityPriceListReset,
  ipfDetailImportExportRestrictionList,
  ipfDetailImportExportRestrictionListReset,
  userDetail,
  gcmfZoneList,
  gcmfSubzoneList,
  gcmfSubzoneListReset,
  ipfDetailDestinationRtaGcmfErrors,
  ipfDetailCommodityPriceCount,
  ipfDetailCommodityPriceCountReset,
  ipfDetailIpfDetail,
  dischargePointDefaultListReset,
  ipfUpdate,
  ipfUpdateReset,
  clearerList,
  ipfDetailSummaryStandardErrors,
  ipfDetailIpfAttachmentAddMultiple,
  ipfDetailIpfAttachmentGet,
  ipfDetailReviewCostsAndTimesErrors, ipfDetailIpfDetailReset
} from "../../actions";
import {
  DATE_FORMAT_INPUT,
  DATE_FORMAT_DATE_PICKER,
  DATE_FORMAT_DEFAULT,
  DATE_FORMAT_STANDARD,
  PRICE_TYPES,
  JUSTIFICATION_OPTIONS
} from "../../constants";

import style from "./style.scss";
import {withRouter} from "react-router-dom";

// A function to get the status of a tab
export const getTabStatus = (currentTab, page) => {
  if (currentTab === page) {
    return "not-stated";
  } else if (currentTab < page) {
    return "locked";
  } else {
    return "complete";
  }
};

// A function to adapt purchase options to be compliant with data expected by the table
export const adaptPurchaseOptions = purchaseOptions =>
  purchaseOptions.map(purchaseOption => ({
    ...purchaseOption,
    packaging_type: (purchaseOption.commodity_price && purchaseOption.commodity_price.packaging_type) || {
      code: "",
      description: ""
    },
    source_of_supply: PRICE_TYPES.find(price => purchaseOption.source_of_supply === price.value) || {
      value: "",
      description: ""
    },
    incoterm_place_country:
      (purchaseOption.commodity_price &&
        purchaseOption.commodity_price.incoterm_place &&
        purchaseOption.commodity_price.incoterm_place.country &&
        purchaseOption.commodity_price.incoterm_place.country.name) ||
      "",
    incoterm_place_name:
      (purchaseOption.commodity_price &&
        purchaseOption.commodity_price.incoterm_place &&
        purchaseOption.commodity_price.incoterm_place.name) ||
      ""
  }));

// A function used to calculate purchase options cost and times
// and return a new set of purchase options
// before to calculate costs and times, data are cleared
// After getting the list of cost and times, best options are calculated
export const calculatePurchaseOptions = (purchaseOptions, costsAndTimes, quantity) => {
  let result = [];
  if (!isEmpty(costsAndTimes) && !isEmpty(purchaseOptions)) {
    result = purchaseOptions.map(purchaseOption => {
      const { times, tmp_id, costs } = purchaseOption;

      const technical_time = (times && times.technical_time) || 0;
      const discharge_shipping_time =
        (costsAndTimes[`discharge_shipping_time_${tmp_id}`] &&
          parseFloat(clearNumber(costsAndTimes[`discharge_shipping_time_${tmp_id}`]))) ||
        0;
      const landside_time =
        (costsAndTimes[`landside_time_${tmp_id}`] &&
          parseFloat(clearNumber(costsAndTimes[`landside_time_${tmp_id}`]))) ||
        0;
      const commodity_cost = (costs && costs.commodity_price) || 0;
      const shipping_cost = (costs && costs.shipping_rate) || 0;
      const port_handling_other_cost =
        (costsAndTimes[`port_handling_other_cost_${tmp_id}`] &&
          parseFloat(clearNumber(costsAndTimes[`port_handling_other_cost_${tmp_id}`]))) ||
        0;
      const landside_cost =
        (costsAndTimes[`landside_cost_${tmp_id}`] &&
          parseFloat(clearNumber(costsAndTimes[`landside_cost_${tmp_id}`]))) ||
        0;
      const quantity_and_quality_cost =
        (costsAndTimes[`quantity_and_quality_cost_${tmp_id}`] &&
          parseFloat(clearNumber(costsAndTimes[`quantity_and_quality_cost_${tmp_id}`]))) ||
        0;
      const clearedQuantity = (quantity && parseFloat(clearNumber(quantity))) || 0;
      const total_time = technical_time + discharge_shipping_time + landside_time;
      const total_cost_mt =
        commodity_cost + shipping_cost + port_handling_other_cost + landside_cost + quantity_and_quality_cost;
      const total_cost = total_cost_mt * clearedQuantity;
      return {
        ...purchaseOption,
        technical_time: formatNumber(technical_time),
        discharge_shipping_time: formatNumber(discharge_shipping_time),
        landside_time: formatNumber(landside_time),
        total_time: formatNumber(total_time),
        commodity_cost: formatNumber(commodity_cost),
        shipping_cost: formatNumber(shipping_cost),
        port_handling_other_cost: formatNumber(port_handling_other_cost),
        landside_cost: formatNumber(landside_cost),
        quantity_and_quality_cost: formatNumber(quantity_and_quality_cost),
        total_cost_mt: formatNumber(total_cost_mt),
        total_cost: formatNumber(total_cost)
      };
    });
  }
  let bestCost = result.reduce(
    (best, current) =>
      parseFloat(clearNumber(current.total_cost)) < best || best === -1
        ? parseFloat(clearNumber(current.total_cost))
        : best,
    -1
  );
  let bestTime = result.reduce(
    (best, current) =>
      parseFloat(clearNumber(current.total_time)) < best || best === -1
        ? parseFloat(clearNumber(current.total_time))
        : best,
    -1
  );
  result = result.map(item => ({
    ...item,
    is_best_cost: parseFloat(clearNumber(item.total_cost)) <= bestCost,
    is_best_time: parseFloat(clearNumber(item.total_time)) <= bestTime
  }));
  return result;
};

export const calculateCommodityCounting = (purchaseOptions) => {
  if (isMinor(purchaseOptions)) {
    return false
  } else return true
}

// A function to calculate the percentage above the best purchase option,
// if the non best cost-effective one was selected
export const calculateIppComparison = (purchaseOptions, selected, ipf) => {
  let selectedOption = false;
  let bestOption = false;
  // check if purchaseOptions were entered manually or saved previously and returned from server
  if (!isEmpty(purchaseOptions) && purchaseOptions[0].hasOwnProperty("tmp_id")) {
    selectedOption = purchaseOptions.find(purchaseOption => purchaseOption.tmp_id === selected) || false;
    bestOption = purchaseOptions.find(purchaseOption => purchaseOption.is_best_cost) || false;
  } else {
    selectedOption = purchaseOptions.find(purchaseOption => purchaseOption.id === selected) || false;
    bestOption = ipf && ipf.best_costs_options?
      purchaseOptions.find(purchaseOption => ipf.best_costs_options.indexOf(purchaseOption.id) !== -1)|| false
      : false;
  }

  const priceDifference = {
    isAboveIpp: "false"
  };

  priceDifference.isAboveIpp = selectedOption && !(selectedOption.is_best_cost);
  if (selectedOption && bestOption && selectedOption.hasOwnProperty("id") && bestOption.id === selectedOption.id) {
    priceDifference.isAboveIpp = !bestOption.id === selectedOption.id;
  }

  if (priceDifference.isAboveIpp && bestOption) {
    if (bestOption.hasOwnProperty("total_cost") && selectedOption.hasOwnProperty("total_cost")) {
      priceDifference.amountAboveIpp = formatNumber(
        clearNumber(selectedOption.total_cost) - clearNumber(bestOption.total_cost)
      );
      priceDifference.percentageAboveIpp = formatNumber(
        (clearNumber(selectedOption.total_cost) * 100) / clearNumber(bestOption.total_cost) - 100
      );
    } else {
      priceDifference.amountAboveIpp = formatNumber(
        clearNumber(selectedOption.total_costs.toString()) - clearNumber(bestOption.total_costs.toString())
      );
      priceDifference.percentageAboveIpp = formatNumber(
        (clearNumber(selectedOption.total_costs.toString()) * 100) / clearNumber(bestOption.total_costs.toString()) - 100
      );
    }
  }
  return priceDifference;
};

// A function to generate a label for each row of the grant select
export const generateGrantLabel = grant => (
  <div className={style.grantWrapper}>
    <span className={style.grantTitle}>{`${grant.code} - ${grant.description}`}</span>
    <span className={style.grantLabel}>Remarks</span>
    <span className={style.grantDescription}>{stringOrDash(grant.general_remark)}</span>
    <span className={style.grantLabel}>Purchase restrictions</span>
    <span className={style.grantDescription}>{stringOrDash(grant.purchase_restriction)}</span>
    <span className={style.grantLabel}>Purchase restrictions remarks</span>
    <span className={style.grantDescription}>{stringOrDash(grant.purchase_restriction_remarks)}</span>
    <span className={style.grantLabel}>TOD</span>
    <span className={style.grantDescription}>
      {stringOrDash(grant.tod && dateToString(grant.tod, DATE_FORMAT_STANDARD, DATE_FORMAT_DEFAULT))}
    </span>
  </div>
);

// A summary is displayed on top of some steps, it contains what user selected in criteria tab
// the summary have to display different data based on IPF type (GCMF or standard)
// this function is used to generate the right component based on IPF type (GCMF or standard)
export const generateSelectedData = (
  isGcmf,
  gcmfValues,
  standardValues,
  destinationStandard,
  destinationGcmf,
  importExportRestriction,
  standardValuesInitial
) =>
  isGcmf ? (
    <SelectedDataGcmf values={gcmfValues} destination={destinationGcmf} />
  ) : (
    <SelectedDataStandard
      values={standardValuesInitial || standardValues}
      destination={destinationStandard}
      importExportRestriction={importExportRestriction}
    />
  );

// A function to adapt values received from backend to the format used by form
export const adaptCriteriaStandardInitialFormValues = values =>
  !isEmpty(values) && {
    commodity: { value: values.commodity.code, label: values.commodity.description },
    quantity: values.quantity,
    small_holder_farmer_standard: { value: values.small_holder_farmer, label: values.small_holder_farmer ? "Yes" : "No" },
    gmo_commodity_standard: values.gmo_commodity,
    project_country: { value: values.project.country.iso_code, label: values.project.country.name },
    project: { value: values.project.code, label: values.project.short_title },
    wbs: { value: values.wbs.code, label: values.wbs.title },
    recipient_country: { value: values.recipient_country.iso_code, label: values.recipient_country.name },
    grant: { value: values.grant.code, label: values.grant.code, data: values.grant }
  };

// A function to adapt values received from backend to the format used by form
export const adaptCriteriaGcmfInitialFormValues = values =>
  !isEmpty(values) && {
    commodity: { value: values.commodity.code, label: values.commodity.description },
    quantity: values.quantity,
    small_holder_farmer_gcmf: { value: values.small_holder_farmer, label: values.small_holder_farmer ? "Yes" : "No" },
    gmo_commodity_standard: values.gmo_commodity,
    gcmf_zone: { value: values.gcmf_zone.code, label: values.gcmf_zone.name },
    gcmf_subzone: { value: values.gcmf_subzone.id, label: values.gcmf_subzone.name, data: values.gcmf_subzone }
  };

// A function to adapt values received from backend to the format used by form
export const adaptDestinationRtaStandardInitialFormValues = values =>
  !isEmpty(values) && {
    discharge_port: { value: values.discharge_port.id, label: values.discharge_port.name },
    destination_point: { value: values.destination_point.id, label: values.destination_point.name },
    rta_discharge_port: stringToDate(values.rta_discharge_port, DATE_FORMAT_INPUT),
    rta_destination_point: stringToDate(values.rta_destination_point, DATE_FORMAT_INPUT)
  };

// A function to adapt values received from backend to the format used by form
export const adaptDestinationRtaGcmfInitialFormValues = values =>
  !isEmpty(values) && {
    discharge_port: { value: values.discharge_port.id, label: values.discharge_port.name },
    rta_discharge_port: stringToDate(values.rta_discharge_port, DATE_FORMAT_INPUT),
    rta_destination_point: stringToDate(values.rta_destination_point, DATE_FORMAT_INPUT)
  };

export const adaptPurchaseOptionsForReviewCostTimesForm = (purchaseOptions, formValues) => {
  // FIXME: for some reason, redux form overwrites entered values by initialValues after going to different page and back.
  //  This temporarily fixes this behaviour by passing entered values as initial
  if(formValues && formValues.values && !isEmpty(formValues.values)) {
    return([formValues.values]);
  } else {
    return purchaseOptions.map(purchaseOption => {
      let isOptionEmpty = purchaseOption.is_empty;
      if (!isOptionEmpty) {
        let data = {};
        data[`discharge_shipping_time_${purchaseOption.tmp_id}`] = purchaseOption.times.port_time.toString();
        data[`landside_time_${purchaseOption.tmp_id}`] = purchaseOption.times.overland_time.toString();
        data[`port_handling_other_cost_${purchaseOption.tmp_id}`] = purchaseOption.costs.port_and_handling_cost.toString();
        data[`landside_cost_${purchaseOption.tmp_id}`] = purchaseOption.costs.overland_price.toString();
        data[`quantity_and_quality_cost_${purchaseOption.tmp_id}`] = purchaseOption.costs.quality_and_quantity.toString();
        return data;
        // to make sure that we don't put zeros as default values and leave input fields empty instead
      } else {
        return null;
      }
    });
  }
};

// A function to adapt values received from backend to the format used by form
export const adaptJustificationInitialFormValues = values => {
  const { justifications } = values;
  const selectedJustifications = {};
  JUSTIFICATION_OPTIONS.map(
    option =>
      (selectedJustifications[option.id] =
        justifications.find(justification => justification === option.value) !== undefined)
  );
  return !isEmpty(values) && selectedJustifications;
};

export const adaptSelectSourceInitialFormValues = (values) => {
  if (!isEmpty(values) && values.next_step >= 6) {
    const purchase_options = values.purchase_options;
    const preferred_option = purchase_options.find(option => option.preferred);
    return preferred_option ? {
      options: String(preferred_option.id) 
    } : null;
  } else {
    return null;
  }
};

// A function to adapt values received from backend to the format used by form
export const adaptSummaryStandardInitialFormValues = values =>
  !isEmpty(values) && {
    clearer: (values.clearer && { value: values.clearer.username, label: values.clearer.full_name }) || undefined,
    comments: values.comments || undefined
  };

export const adaptSummaryGcmfInitialFormValues = values =>
  !isEmpty(values) && {
    comments: values.comments || undefined
  };

// Starting from a list of commodity prices and a list of purchase options
// we have to select the prices having the same incoterm, incoterm place and source type
// that are in the list of purchase option
export const adaptCompareSourceInitialFormValues = (values, sources) =>
  !isEmpty(values) &&
  sources.reduce((total, source) => {
    let selected = false;
    if (
      values.purchase_options.find(
        option =>
          option.incoterm_place.code === source.incoterm_place.code &&
          option.incoterm.code === source.incoterm.code &&
          option.source_of_supply === source.price_type &&
          option.packaging_type.description === source.packaging_type.description
      )
    ) {
      selected = true;
    }
    return { ...total, [`source_${source.id}`]: selected };
  }, {});

export const initialJustificationBoolean = (ippComparison, commodityCounting) => {
  if (ippComparison && !commodityCounting){
    return true;
  } else if (!ippComparison && !commodityCounting) {
    return true;
    } else if (ippComparison && commodityCounting) {
      return true;
    }
  return false;  
};
// A component that shows the new IPF page
export class IPFDetail extends Component {
  // On component mount, data are loaded
  async componentDidMount() {
    this.onClickNextOnCriteriaStandard = this.onClickNextOnCriteriaStandard.bind(this);
    this.onClickDraftOnCriteriaStandard = this.onClickDraftOnCriteriaStandard.bind(this);
    this.onClickNextOnCriteriaGcmf = this.onClickNextOnCriteriaGcmf.bind(this);
    this.onClickDraftOnCriteriaGcmf = this.onClickDraftOnCriteriaGcmf.bind(this);
    this.onClickNextOnCompareSources = this.onClickNextOnCompareSources.bind(this);
    this.onClickDraftOnCompareSources = this.onClickDraftOnCompareSources.bind(this);
    this.onClickNextOnSelectSource = this.onClickNextOnSelectSource.bind(this);
    this.onClickDraftOnSelectSource = this.onClickDraftOnSelectSource.bind(this);
    this.onClickNextOnJustification = this.onClickNextOnJustification.bind(this);
    this.onClickDraftOnJustification= this.onClickDraftOnJustification.bind(this);
    this.onClickNextOnDestinationAndRtaStandard = this.onClickNextOnDestinationAndRtaStandard.bind(this);
    this.onClickDraftOnDestinationAndRtaStandard = this.onClickDraftOnDestinationAndRtaStandard.bind(this);
    this.onClickNextOnDestinationAndRtaGcmf = this.onClickNextOnDestinationAndRtaGcmf.bind(this);
    this.onClickDraftOnDestinationAndRtaGcmf = this.onClickDraftOnDestinationAndRtaGcmf.bind(this);
    this.onClickNextOnSummaryStandard = this.onClickNextOnSummaryStandard.bind(this);
    this.onClickNextOnSummaryGcmf = this.onClickNextOnSummaryGcmf.bind(this);
    this.onClickPreviousOnCompareSources = this.onClickPreviousOnCompareSources.bind(this);
    this.onClickPreviousOnSelectSource = this.onClickPreviousOnSelectSource.bind(this);
    this.onClickPreviousOnJustification = this.onClickPreviousOnJustification.bind(this);
    this.onClickPreviousOnDestinationAndRtaStandard = this.onClickPreviousOnDestinationAndRtaStandard.bind(this);
    this.onClickPreviousOnDestinationAndRtaGcmf = this.onClickPreviousOnDestinationAndRtaGcmf.bind(this);
    this.onClickPreviousOnSummaryStandard = this.onClickPreviousOnSummaryStandard.bind(this);
    this.onClickPreviousOnSummaryGcmf = this.onClickPreviousOnSummaryGcmf.bind(this);
    this.onSelectProjectCountry = this.onSelectProjectCountry.bind(this);
    this.onSelectGcmfZone = this.onSelectGcmfZone.bind(this);
    this.onSelectProject = this.onSelectProject.bind(this);
    this.dischargePoints = this.dischargePoints.bind(this);
    this.grants = this.grants.bind(this);
    this.isOutsideRangeDestinationPointDate = this.isOutsideRangeDestinationPointDate.bind(this);
    this.onChangeDischargePortDateStandard = this.onChangeDischargePortDateStandard.bind(this);
    this.onChangeDischargePortDateGcmf = this.onChangeDischargePortDateGcmf.bind(this);
    this.onSelectFinalDestinationCountry = this.onSelectFinalDestinationCountry.bind(this);
    this.onChangeDischargePort = this.onChangeDischargePort.bind(this);
    this.onValidateSuccessIpfAddModal = this.onValidateSuccessIpfAddModal.bind(this);
    this.onSelectCommodityPrice = this.onSelectCommodityPrice.bind(this);
    this.onClickPreviousOnReviewCostsAndTimes = this.onClickPreviousOnReviewCostsAndTimes.bind(this);
    this.onClickNextOnReviewCostsAndTimes = this.onClickNextOnReviewCostsAndTimes.bind(this);
    this.onClickDraftOnReviewCostsAndTimes = this.onClickDraftOnReviewCostsAndTimes.bind(this);
    this.props.countryList();
    this.props.specificCommodityList();
    this.props.userDetail();
    this.props.gcmfZoneList();
    this.props.clearerList();
    if (this.props.isOnEdit) {
      await this.props.ipfDetailIpfDetail(this.props.id);
      if (this.props.ipf && this.props.ipf.is_partial) {
        const nextStep = this.props.ipf.next_step > 2 &&  this.props.ipf.next_step <= 5 ?
          2 : this.props.ipf.next_step - 1;
        if (nextStep >= 2) {
          if (this.props.isGcmf) {
            const { selectedCommodity, selectedDischargePort } = this.props;
            await this.filterCommodityPrices(selectedCommodity, selectedDischargePort.value, "1");
          } else {
            const { selectedCommodity, selectedDestinationPoint } = this.props;
            await this.filterCommodityPrices(selectedCommodity, selectedDestinationPoint.value, "0");
          }
        }

        if(this.props.ipf.attachments.length>0){
          
          const attachment_client = Axios.create({
            baseURL: process.env.API_BASE_URL,
            withXSRFToken: true,
            responseType: "arraybuffer",
            withCredentials: true,
            xsrfCookieName: `${process.env.CSRFTOKEN}`,
            xsrfHeaderName: "X-CSRFTOKEN"
          });

          const promises = await this.props.ipf.attachments.map(attachment => attachment_client.get(`${attachment.url}`, { responseType: 'arraybuffer' }));

          Promise.all(promises)
            .then((result) => {
              const dataTransfer = new DataTransfer();
              
              result.map(result => {dataTransfer.items.add(new File([result.data], this.props.ipf.attachments.find(attachment => (attachment.url == result.config.url)).filename, {
                type: result.headers["content-type"],
              }))});
              
              this.props.files.push(dataTransfer.files);
              return result;
            })
            .catch(error => {
              console.log(error);
              return {};
            });
        }

        this.changeCurrentTab(nextStep);
      }
    }
  }

  // On component unmount data are reset
  componentWillUnmount() {
    this.changeCurrentTab(0, false);
    this.props.ipfDetailCriteriaStandardErrors({});
    this.props.ipfDetailCriteriaGcmfErrors({});
    this.props.ipfDetailDestinationRtaStandardErrors({});
    this.props.ipfDetailDestinationRtaGcmfErrors({});
    this.props.ipfDetailCompereSourcesErrors({});
    this.props.ipfAddReset();
    this.props.hideSuccessIpfAddModal();
    this.props.ipfDetailCommodityPriceListReset();
    this.props.ipfDetailCommodityPriceCountReset();
    this.props.ipfDetailImportExportRestrictionListReset();
    this.props.projectListReset();
    this.props.wbsListReset();
    this.props.deliveryPointListReset();
    this.props.dischargePointDefaultListReset();
    this.props.ipfUpdateReset();
    this.props.ipfDetailIpfDetailReset();
    this.props.ipfDetailCommodityPriceOptionsReset();
    this.props.files.length=0;
    // to prevent user from navigating back to details page using browser 'back' button
    this.props.history.goForward();
  }

  // on component initialization lists
  // have to be filled with filtered data
  async componentDidUpdate() {
    const { initialFormValuesCriteriaStandard, initialFormValuesCriteriaGcmf, isGcmf, isOnEdit } = this.props;
    if (
      !isGcmf &&
      isOnEdit &&
      isEmpty(this.props.projects) &&
      isEmpty(this.props.wbs) &&
      isEmpty(this.props.deliveryPoints) &&
      isEmpty(this.props.dischargePointDefaults) &&
      isEmpty(this.props.importExportRestriction) &&
      !isEmpty(initialFormValuesCriteriaStandard) &&
      !this.props.isFetchingProjects &&
      !this.props.isFetchingWbs &&
      !this.props.isFetchingDeliveryPoints &&
      !this.props.isFetchingDischargePointDefaults &&
      !this.props.isFetchingImportExportRestrictions
    ) {
      const { project, project_country } = initialFormValuesCriteriaStandard;

      this.props.projectList(project_country.value);
      this.props.wbsList(project.value);
      this.props.deliveryPointList(project_country.value);
      this.props.dischargePointDefaultList(project_country.value);
      this.props.ipfDetailImportExportRestrictionList(project_country.value);
    }
    if (
      isGcmf &&
      isOnEdit &&
      isEmpty(this.props.gcmfSubzones) &&
      !isEmpty(initialFormValuesCriteriaGcmf) &&
      !this.props.isFetchingGcmfSubzones
    ) {
      this.props.gcmfSubzoneList(initialFormValuesCriteriaGcmf.gcmf_zone.value);
    }
  }

  // When user click next on criteria standard tab
  onClickNextOnCriteriaStandard() {
    if (this.validateCriteriaStandardTab()) {
      this.changeCurrentTab(1);
    }
  }

  onClickDraftOnCriteriaStandard() {
    if (this.validateCriteriaStandardTab()) {
      this.submitDraft();
    }
  }

  // When user click next on criteria gcmf tab
  onClickNextOnCriteriaGcmf() {
    if (this.validateCriteriaGcmfTab()) {
      this.changeCurrentTab(1);
    }
  }

  onClickDraftOnCriteriaGcmf() {
    if (this.validateCriteriaGcmfTab()) {
      this.submitDraft();
    }
  }

  // When user click next on destination and rta standard tab
  // commodity prices are filtered with selected data
  onClickNextOnDestinationAndRtaStandard() {
    if (this.validateDestinationRtaStandardTab()) {
      const { selectedCommodity, selectedDestinationPoint } = this.props;
      this.filterCommodityPrices(selectedCommodity, selectedDestinationPoint.value, "0");
      this.changeCurrentTab(2);
    }
  }
  onClickDraftOnDestinationAndRtaStandard() {
    if (this.validateDestinationRtaStandardTab()) {
      this.submitDraft();
    }
  }

  // When user click next on destination and rta gcmf tab
  // commodity prices are filtered with selected data
  // in GCMF destination point is equal to destination port
  onClickNextOnDestinationAndRtaGcmf() {
    if (this.validateDestinationRtaGcmfTab()) {
      const { selectedCommodity, selectedDischargePort } = this.props;
      this.filterCommodityPrices(selectedCommodity, selectedDischargePort.value, "1");
      this.changeCurrentTab(2);
    }
  }
  onClickDraftOnDestinationAndRtaGcmf() {
    if (this.validateDestinationRtaGcmfTab()) {
      this.submitDraft();
    }
  }

  // When user click next on compare sources tab
  onClickNextOnCompareSources() {
    if (this.validateCompareSources()) {
      this.getPurchaseOptions();
      this.changeCurrentTab(3);
    }
  }
  async onClickDraftOnCompareSources() {
    if (this.validateCompareSources()) {
      await this.getPurchaseOptions();
      this.submitDraft();
    }
  }

  // After insert cost and times, select source tab i displayed
  onClickNextOnReviewCostsAndTimes() {
    if (this.validateReviewCostsAndTimes()) {
      this.props.resetSelectSourceForm();
      this.changeCurrentTab(4);
    }
  }

  onClickDraftOnReviewCostsAndTimes() {
    if (this.validateReviewCostsAndTimes()) {
      this.submitDraft();
    }
  }

  // When user click next on select source tab,
  // justification error message have to be cleared
  // because, in case of the best cost-effective option is selected
  // user is not bound to select a justification
  onClickNextOnSelectSource() {
    if (this.validateSelectSource()) {
      this.props.ipfDetailJustificationErrors({});
      this.changeCurrentTab(5);
      if (!this.props.ippComparison.isAboveIpp) {
        this.props.resetJustificationsForm();
      }
    }
  }

  onClickDraftOnSelectSource() {
    if (this.validateSelectSource()) {
      this.submitDraft();
    }
  }

  // When user click next on justification tab
  onClickNextOnJustification() {
    if (this.validateJustification()) {
      this.changeCurrentTab(6);
    }
  }

  onClickDraftOnJustification() {
    if (this.validateJustification()) {
      this.submitDraft();
    }
  }

  // When user click next on summary standard ipf is saved
  // if user inserted a clearer
  onClickNextOnSummaryStandard() {
    if (this.validateSummaryStandard()) {
      this.submit();
    }
  }

  // When user click next on summary gmgm ipf is saved
  onClickNextOnSummaryGcmf() {
    this.submit();
  }

  // When user click ok on success modal, it is redirected to IPF list page
  // and the page is scrolled to top
  onValidateSuccessIpfAddModal() {
    this.props.hideSuccessIpfAddModal();
    this.props.history.push("/");
    window.scrollTo(0, 0);
  }

  // When user click previous on destination and rta standard tab
  onClickPreviousOnDestinationAndRtaStandard() {
    this.changeCurrentTab(0);
  }

  // When user click previous on destination and rta gcmf tab
  onClickPreviousOnDestinationAndRtaGcmf() {
    this.changeCurrentTab(0);
  }

  // When user click previous on compare sources tab
  onClickPreviousOnCompareSources() {
    this.changeCurrentTab(1);
  }

  // When user click previous on review cost and times tab
  onClickPreviousOnReviewCostsAndTimes() {
    this.changeCurrentTab(2);
  }

  // When user click previous on select source tab
  onClickPreviousOnSelectSource() {
    this.getPurchaseOptions();
    this.changeCurrentTab(3);
  }

  // When user click previous on justification tab
  onClickPreviousOnJustification() {
    this.changeCurrentTab(4);
  }

  // When user click previous on summary standard tab
  onClickPreviousOnSummaryStandard() {
    this.changeCurrentTab(5);
  }

  // When user click previous on summary gcmf tab
  onClickPreviousOnSummaryGcmf() {
    this.changeCurrentTab(5);
  }

  // Validate the criteria Standard step frontend side to show errors related to user inserted data
  validateCriteriaStandardTab() {
    const fields = [
      "commodity",
      "quantity",
      "project_country",
      "project",
      "wbs",
      "recipient_country",
      "grant",
      "ackMessage",
      "small_holder_farmer_standard"
    ];
    const errors = validateData(this.props.ipfDetailCriteriaStandardFormValues, fields);
    this.props.ipfDetailCriteriaStandardErrors({});
    this.props.ipfDetailCriteriaStandardErrors(errors);
    return isEmpty(errors);
  }

  // Validate the criteria GCMF step frontend side to show errors related to user inserted data
  validateCriteriaGcmfTab() {
    const { ipfDetailCriteriaGcmfErrors, ipfDetailCriteriaGcmfFormValues } = this.props;
    const fields = [
      "commodity",
      "quantity",
      "small_holder_farmer_gcmf",
      "gcmf_zone",
      "gcmf_subzone"
    ];
    const errors = validateData(ipfDetailCriteriaGcmfFormValues, fields);
    ipfDetailCriteriaGcmfErrors({});
    ipfDetailCriteriaGcmfErrors(errors);
    return isEmpty(errors);
  }

  // Validate the destination and rta standard step frontend side to show errors related to user inserted data
  validateDestinationRtaStandardTab() {
    const { ipfDetailDestinationRtaStandardErrors, ipfDetailDestinationRtaStandardFormValues } = this.props;
    const fields = ["discharge_port", "destination_point", "rta_discharge_port", "rta_destination_point"];
    const errors = validateData(ipfDetailDestinationRtaStandardFormValues, fields);
    ipfDetailDestinationRtaStandardErrors({});
    ipfDetailDestinationRtaStandardErrors(errors);
    return isEmpty(errors);
  }

  // Validate the destination and rta gcmf step frontend side to show errors related to user inserted data
  validateDestinationRtaGcmfTab() {
    const { ipfDetailDestinationRtaGcmfErrors, ipfDetailDestinationRtaGcmfFormValues } = this.props;
    const fields = ["discharge_port", "rta_discharge_port", "rta_destination_point"];
    const errors = validateData(ipfDetailDestinationRtaGcmfFormValues, fields);
    ipfDetailDestinationRtaGcmfErrors({});
    ipfDetailDestinationRtaGcmfErrors(errors);
    return isEmpty(errors);
  }

  // Validate the compare sources frontend side to show errors related to user inserted data
  // user have to select at least one source from the table
  validateCompareSources() {
    const { ipfDetailCompereSourcesErrors, ipfDetailCompareSourcesFormValues } = this.props;
    ipfDetailCompereSourcesErrors({});
    if (isEmpty(ipfDetailCompareSourcesFormValues)) {
      ipfDetailCompereSourcesErrors({ message: "Select at least one source from the table" });
      return false;
    }
    /*if(isMinor(ipfDetailCompareSourcesFormValues)) {
      <PopupMenu id={1} items={[{
        key: 1, label: "label", visible: true
      }]} />
      ipfDetailCompereSourcesErrors({ message: "Select at least more than two sources" });
      return false;
    }*/
    return true;
  }

  // Validate the select source frontend side to show errors related to user inserted data
  // user have to select one source from the table
  validateSelectSource() {
    const { ipfDetailSelectSourceErrors, ipfDetailSelectSourceFormValues } = this.props;
    ipfDetailSelectSourceErrors({});
    if (isEmpty(ipfDetailSelectSourceFormValues)) {
      ipfDetailSelectSourceErrors({ message: "Select one source from the table" });
      return false;
    }
    return true;
  }

  // Validate if at least a justification is provided if non best cost-effective option vas selected
  validateJustification() {
    const { ipfDetailJustificationErrors, ippComparison, ipfDetailJustificationFormValues, commodityCounting } = this.props;
    ipfDetailJustificationErrors({});
    if (commodityCounting) {
      if (
        ippComparison.isAboveIpp &&
        Object.values(ipfDetailJustificationFormValues).filter(value => value).length === 0
      ) {
        ipfDetailJustificationErrors({ message: "Select at least one justification" });
        return false;
      }
    } else if (
      !commodityCounting &&
      Object.values(ipfDetailJustificationFormValues).filter(value => value).length === 0
      ) {
        ipfDetailJustificationErrors({ message: "Select at least one justification" });
        return false;
      }
    return true;
  }

  // Validate the select source frontend side to show errors related to user inserted data
  // user have to select one source from the table
  validateSummaryStandard() {
    const { ipfDetailSummaryStandardErrors, ipfDetailSummaryStandardFormValues } = this.props;
    const fields = ["clearer"];
    const errors = validateData(ipfDetailSummaryStandardFormValues, fields);
    ipfDetailSummaryStandardErrors({});
    ipfDetailSummaryStandardErrors(errors);
    return isEmpty(errors);
  }

  // Validate review costs and times for each purchase option
  // each field of form have to be equal or great than zero
  validateReviewCostsAndTimes() {
    const {
      ipfDetailReviewCostsAndTimesErrors,
      ipfDetailReviewCostsAndTimesFormFields,
      ipfDetailReviewCostsAndTimesFormValues
    } = this.props;
    const fields = Object.keys(ipfDetailReviewCostsAndTimesFormFields);
    const errors = validateData(ipfDetailReviewCostsAndTimesFormValues, fields);
    ipfDetailReviewCostsAndTimesErrors({});
    ipfDetailReviewCostsAndTimesErrors(errors);
    return isEmpty(errors);
  }

  // Get the list of purchase options by doing a POST for each selected commodity price
  // if it is a GCMF, final_destination_loc is equal to discharge port
  async getPurchaseOptions() {
    const {
      ipfDetailCompareSourcesFormValues,
      selectedQuantity,
      selectedDestinationPoint,
      selectedDischargePort,
      isGcmf,
      ipf,
      ipfDetailCommodityPriceOptions,
    } = this.props;
    // try to pass default values to ReviewCostsAndPrices, but initialValues don't have necessary temporary id
    const data = {
      commodity_prices: ipfDetailCompareSourcesFormValues.map(value => value.replace("source_", "")),
      quantity: selectedQuantity,
      final_destination_loc: selectedDestinationPoint.value || selectedDischargePort.value,
      discharge_point_loc: selectedDischargePort.value,
      is_gcmf: isGcmf,
      ipf: ipf
    };
    await ipfDetailCommodityPriceOptions(data);
  }

  submitDraft () {
    const {currentTab} = this.props;
    if (currentTab === 6) {
      this.submit();
    } else {
      const { ipfDetailJustificationFormValues, isGcmf, isOnEdit, id, ipf,
        calculatedPurchaseOptions, purchaseOptions } = this.props;
      let justifications = [];
      if (ipfDetailJustificationFormValues) {
        justifications = JUSTIFICATION_OPTIONS.map(option =>
          ipfDetailJustificationFormValues[option.id] ? option.value : undefined
        ).filter(justification => justification);
      }
      let purchase_options = [];
      // if ipf was previously saved and data wasn't changed, we get saved values
      if(isEmpty(purchaseOptions) && ipf && !isEmpty(ipf.purchase_options)) {
        purchase_options = ipf.purchase_options.map((purchaseOption) => ({
          source_of_supply: purchaseOption.source_of_supply,
          packaging_type: purchaseOption.packaging_type.code,
          preferred: this.props.ipfDetailSelectSourceFormValues.options?
            Number(this.props.ipfDetailSelectSourceFormValues.options) === purchaseOption.id : purchaseOption.preferred,
          specific_commodity: purchaseOption.specific_commodity.code,
          destination_point: ipf.destination_point.id,
          origin_country:
            purchaseOption.origin_country.iso_code ||
            purchaseOption.origin_country.ISO_code,
          incoterm_place: purchaseOption.incoterm_place.id,
          incoterm: purchaseOption.incoterm.code,
          discharge_point: purchaseOption.discharge_point.id,
          processing_time: purchaseOption.processing_time,
          port_time: purchaseOption.port_time,
          overland_time: purchaseOption.overland_time,
          port_and_handling_cost: purchaseOption.port_and_handling_cost,
          overland_transportation_cost: purchaseOption.overland_transportation_cost,
          quality_and_quantity: purchaseOption.quality_and_quantity,
          commodity_cost: purchaseOption.commodity_cost,
          ocean_transportation_cost: purchaseOption.ocean_transportation_cost
        }));
      }
      // if step 3 is filled and others are not, we get partial data from purchaseOptions
      else if (!isEmpty(purchaseOptions) && isEmpty(calculatedPurchaseOptions)) {
        purchase_options = purchaseOptions.map(purchaseOption => ({
          source_of_supply: purchaseOption.source_of_supply.value,
          packaging_type: purchaseOption.packaging_type.code,
          specific_commodity: purchaseOption.commodity_price.specific_commodity.code,
          destination_point: purchaseOption.final_destination_loc,
          origin_country:
            purchaseOption.commodity_price.origin_country.iso_code ||
            purchaseOption.commodity_price.origin_country.ISO_code,
          incoterm_place: purchaseOption.commodity_price.incoterm_place.id,
          incoterm: purchaseOption.commodity_price.incoterm.code,
          discharge_point: purchaseOption.discharge_point_loc.id,
        }));
        // if steps after 3 are filled, we get all data from calculatedPurchaseOptions
      } else if (!isEmpty(calculatedPurchaseOptions)) {
        purchase_options = calculatedPurchaseOptions.map(purchaseOption => ({
          source_of_supply: purchaseOption.source_of_supply.value,
          packaging_type: purchaseOption.packaging_type.code,
          preferred: this.props.ipfDetailSelectSourceFormValues?
            purchaseOption.tmp_id === Number(this.props.ipfDetailSelectSourceFormValues.options)
            : null,
          specific_commodity: purchaseOption.commodity_price.specific_commodity.code,
          destination_point: purchaseOption.final_destination_loc,
          origin_country:
            purchaseOption.commodity_price.origin_country.iso_code ||
            purchaseOption.commodity_price.origin_country.ISO_code,
          incoterm_place: purchaseOption.commodity_price.incoterm_place.id,
          incoterm: purchaseOption.commodity_price.incoterm.code,
          discharge_point: purchaseOption.discharge_point_loc.id,
          processing_time: clearNumber(purchaseOption.technical_time),
          port_time: clearNumber(purchaseOption.discharge_shipping_time),
          overland_time: clearNumber(purchaseOption.landside_time),
          port_and_handling_cost: clearNumber(purchaseOption.port_handling_other_cost),
          overland_transportation_cost: clearNumber(purchaseOption.landside_cost),
          quality_and_quantity: clearNumber(purchaseOption.quantity_and_quality_cost),
          commodity_cost: clearNumber(purchaseOption.commodity_cost),
          ocean_transportation_cost: clearNumber(purchaseOption.shipping_cost)
        }));
      }
      let criteriaData = {};
      let destinationAndRtaData = {};
      if (isGcmf) {
        const { ipfDetailCriteriaGcmfFormValues, ipfDetailDestinationRtaGcmfFormValues } = this.props;
        const {
          commodity,
          quantity,
          gcmf_zone,
          gcmf_subzone,
          small_holder_farmer_gcmf,
          gmo_commodity_gcmf
        } = ipfDetailCriteriaGcmfFormValues;

        criteriaData = {
          commodity: commodity.value,
          quantity: clearNumber(quantity),
          gcmf_zone: gcmf_zone.value,
          gcmf_subzone: gcmf_subzone.value,
          small_holder_farmer: small_holder_farmer_gcmf.value,
          gmo_commodity: gmo_commodity_gcmf
        };
        if(!isEmpty(ipfDetailDestinationRtaGcmfFormValues)) {
          const {discharge_port, rta_destination_point, rta_discharge_port} = ipfDetailDestinationRtaGcmfFormValues;
          destinationAndRtaData = {
            destination_point: discharge_port.value,
            rta_destination_point: dateToString(rta_destination_point, DATE_FORMAT_DATE_PICKER, DATE_FORMAT_INPUT),
            discharge_port: discharge_port.value,
            rta_discharge_port: dateToString(rta_discharge_port, DATE_FORMAT_DATE_PICKER, DATE_FORMAT_INPUT)
          };
        }
      } else {
        const {ipfDetailCriteriaStandardFormValues, ipfDetailDestinationRtaStandardFormValues} = this.props;
        const {
          commodity,
          quantity,
          project,
          project_country,
          wbs,
          recipient_country,
          grant,
          small_holder_farmer_standard,
          gmo_commodity_standard
        } = ipfDetailCriteriaStandardFormValues;

        criteriaData = {
          commodity: commodity.value,
          quantity: clearNumber(quantity),
          project_country: project_country.value,
          project: project.value,
          wbs: wbs.value,
          recipient_country: recipient_country.value,
          grant: grant.value,
          small_holder_farmer: small_holder_farmer_standard.value,
          gmo_commodity: gmo_commodity_standard
        };

        if (!isEmpty(ipfDetailDestinationRtaStandardFormValues)) {
          const {
            discharge_port,
            destination_point,
            rta_destination_point,
            rta_discharge_port
          } = ipfDetailDestinationRtaStandardFormValues;


          destinationAndRtaData = {
            destination_point: destination_point.value,
            rta_destination_point: dateToString(rta_destination_point, DATE_FORMAT_DATE_PICKER, DATE_FORMAT_INPUT),
            discharge_port: discharge_port.value,
            rta_discharge_port: dateToString(rta_discharge_port, DATE_FORMAT_DATE_PICKER, DATE_FORMAT_INPUT)
          };
        }
      }
      const comments = isGcmf ? this.props.ipfDetailSummaryGcmfFormValues.comments :
        this.props.ipfDetailSummaryStandardFormValues.comments;
      const data = {
        ...criteriaData,
        ...destinationAndRtaData,
        justifications,
        justifications_notes: "",
        comments: comments,
        purchase_options,
        is_gcmf: isGcmf,
        is_partial: true,
        next_step: currentTab + 1,
      };

      if (isOnEdit) {
        this.props.ipfUpdate(id, data).then(result => {
          if (result) {
            this.uploadAttachmentsShowSuccess(id);
          }
        });
      } else {
        this.props.ipfAdd(data).then(result => {
          if (!isEmpty(result)) {
            this.uploadAttachmentsShowSuccess(result.id);
          }
        });
      }
    }
  }

  // When user clicks submit all data in forms are sent to server
  // to be stored, the server will give us back a response with new created IPF
  // After success a modal is displayed
  // Data inserted in criteria data are related to IPF type (Standard or GCMF)
  // in case of GCMF, destination_point is equal to discharge port
  // after saving the IPF, files are uploaded
  // if upload fails, an error message is displayed
  submit() {
    const { ipfDetailJustificationFormValues, isGcmf, isOnEdit, id, calculatedPurchaseOptions, ipf, purchaseOptions } = this.props;

    const justifications = JUSTIFICATION_OPTIONS.map(option =>
      ipfDetailJustificationFormValues[option.id] ? option.value : undefined
    ).filter(justification => justification);
    let purchase_options = [];
    if(isOnEdit && ipf && ipf.next_step && ipf.next_step > 4 && purchaseOptions.length === 0) {
      purchase_options = ipf.purchase_options.map(purchaseOption => ({
        source_of_supply: purchaseOption.source_of_supply,
        packaging_type: purchaseOption.packaging_type.code,
        preferred: purchaseOption.id === Number(this.props.ipfDetailSelectSourceFormValues.options)
          || purchaseOption.preferred,
        specific_commodity: purchaseOption.specific_commodity.code,
        destination_point: ipf.destination_point.id,
        origin_country:
          purchaseOption.origin_country.iso_code ||
          purchaseOption.origin_country.ISO_code,
        incoterm_place: purchaseOption.incoterm_place.id,
        incoterm: purchaseOption.incoterm.code,
        discharge_point: purchaseOption.discharge_point.id,
        processing_time: purchaseOption.processing_time,
        port_time: purchaseOption.port_time,
        overland_time: purchaseOption.overland_time,
        port_and_handling_cost: purchaseOption.port_and_handling_cost,
        overland_transportation_cost: purchaseOption.overland_transportation_cost,
        quality_and_quantity: purchaseOption.quality_and_quantity,
        commodity_cost: purchaseOption.commodity_cost,
        ocean_transportation_cost: purchaseOption.ocean_transportation_cost
      }));
    } else {
      purchase_options = calculatedPurchaseOptions.map(purchaseOption => ({
        source_of_supply: purchaseOption.source_of_supply.value,
        packaging_type: purchaseOption.packaging_type.code,
        preferred: purchaseOption.tmp_id === Number(this.props.ipfDetailSelectSourceFormValues.options),
        specific_commodity: purchaseOption.commodity_price.specific_commodity.code,
        destination_point: purchaseOption.final_destination_loc,
        origin_country:
          purchaseOption.commodity_price.origin_country.iso_code ||
          purchaseOption.commodity_price.origin_country.ISO_code,
        incoterm_place: purchaseOption.commodity_price.incoterm_place.id,
        incoterm: purchaseOption.commodity_price.incoterm.code,
        discharge_point: purchaseOption.discharge_point_loc.id,
        processing_time: clearNumber(purchaseOption.technical_time),
        port_time: clearNumber(purchaseOption.discharge_shipping_time),
        overland_time: clearNumber(purchaseOption.landside_time),
        port_and_handling_cost: clearNumber(purchaseOption.port_handling_other_cost),
        overland_transportation_cost: clearNumber(purchaseOption.landside_cost),
        quality_and_quantity: clearNumber(purchaseOption.quantity_and_quality_cost),
        commodity_cost: clearNumber(purchaseOption.commodity_cost),
        ocean_transportation_cost: clearNumber(purchaseOption.shipping_cost)
      }));
    }
    let criteriaData = {};
    let destinationAndRtaData = {};
    if (isGcmf) {
      const { ipfDetailCriteriaGcmfFormValues, ipfDetailDestinationRtaGcmfFormValues } = this.props;
      const {
        commodity,
        quantity,
        gcmf_zone,
        gcmf_subzone,
        small_holder_farmer_gcmf,
        gmo_commodity_gcmf
      } = ipfDetailCriteriaGcmfFormValues;

      criteriaData = {
        commodity: commodity.value,
        quantity: clearNumber(quantity),
        gcmf_zone: gcmf_zone.value,
        gcmf_subzone: gcmf_subzone.value,
        small_holder_farmer: small_holder_farmer_gcmf?.value,
        gmo_commodity: gmo_commodity_gcmf
      };

      const { discharge_port, rta_destination_point, rta_discharge_port } = ipfDetailDestinationRtaGcmfFormValues;

      destinationAndRtaData = {
        destination_point: discharge_port.value,
        rta_destination_point: dateToString(rta_destination_point, DATE_FORMAT_DATE_PICKER, DATE_FORMAT_INPUT),
        discharge_port: discharge_port.value,
        rta_discharge_port: dateToString(rta_discharge_port, DATE_FORMAT_DATE_PICKER, DATE_FORMAT_INPUT)
      };
    } else {
      const { ipfDetailCriteriaStandardFormValues, ipfDetailDestinationRtaStandardFormValues } = this.props;
      const {
        commodity,
        quantity,
        project,
        project_country,
        wbs,
        recipient_country,
        grant,
        small_holder_farmer_standard,
        gmo_commodity_standard
      } = ipfDetailCriteriaStandardFormValues;

      criteriaData = {
        commodity: commodity.value,
        quantity: clearNumber(quantity),
        project_country: project_country.value,
        project: project.value,
        wbs: wbs.value,
        recipient_country: recipient_country.value,
        grant: grant.value,
        small_holder_farmer: small_holder_farmer_standard.value,
        gmo_commodity: gmo_commodity_standard
      };

      const {
        discharge_port,
        destination_point,
        rta_destination_point,
        rta_discharge_port
      } = ipfDetailDestinationRtaStandardFormValues;

      destinationAndRtaData = {
        destination_point: destination_point.value,
        rta_destination_point: dateToString(rta_destination_point, DATE_FORMAT_DATE_PICKER, DATE_FORMAT_INPUT),
        discharge_port: discharge_port.value,
        rta_discharge_port: dateToString(rta_discharge_port, DATE_FORMAT_DATE_PICKER, DATE_FORMAT_INPUT)
      };
    }
    const clearer = isGcmf ? undefined : this.props.ipfDetailSummaryStandardFormValues.clearer.value;
    const comments = isGcmf ? this.props.ipfDetailSummaryGcmfFormValues.comments :
      this.props.ipfDetailSummaryStandardFormValues.comments;
    const data = {
      ...criteriaData,
      ...destinationAndRtaData,
      justifications,
      justifications_notes: "",
      clearer,
      comments: comments,
      purchase_options,
      is_gcmf: isGcmf
    };

    if (isOnEdit) {
      this.props.ipfUpdate(id, data).then(result => {
        if (result) {
          this.uploadAttachmentsShowSuccess(id);
        }
      });
    } else {
      this.props.ipfAdd(data).then(result => {
        if (!isEmpty(result)) {
          this.uploadAttachmentsShowSuccess(result.id);
        }
      });
    }
  }

  // A function to upload attachments
  uploadAttachmentsShowSuccess(id) {
    let requests = [];
    let clear = [];
    const files = document.getElementById("attachments").files;
    const data = new FormData();
    clear.push({ url: `${id}/remove_attachments/`, data });
    this.removeAttachments(clear).then(()=> {
      Object.keys(files).map(key => {
        const data = new FormData();
        data.append("file", files[key]);
        requests.push({ url: `${id}/add_attachment/${files[key].name}/`, data });
      });
      this.props.ipfDetailIpfAttachmentAddMultiple(requests).then(() => {
        this.props.showSuccessIpfAddModal();
      });
    });
  }

  async removeAttachments(clear){
    await this.props.ipfDetailIpfAttachmentAddMultiple(clear);
  }

  // On select commodity, prices are retrieved and, if there are no prices
  // a warning message is displayed
  onSelectCommodityPrice(data) {
    if (data) {
      this.props.ipfDetailCommodityPriceCount(data.value);
    } else {
      this.props.ipfDetailCommodityPriceCountReset();
    }
  }

  // On select project country, projects are filtered and fields are cleared due to a new country selected
  // if user click "x" button to clear che selection, data will be null
  // final destination country is automatically selected
  // so the code related to selection of final destination country have to be called
  onSelectProjectCountry(data) {
    this.props.clearProject();
    this.props.clearWbs();
    this.props.wbsListReset();
    this.props.projectListReset();
    this.props.changeFinalDestinationCounty(data);
    this.onSelectFinalDestinationCountry(data);
    if (data && data.value) {
      this.props.projectList(data.value);
    }
  }
  // On select project, wbs are filtered and wbs is cleared due to a new project selected
  // if user click "x" button to clear che selection, data will be null
  onSelectProject(data) {
    this.props.clearWbs();
    this.props.wbsListReset();
    if (data && data.value) {
      this.props.wbsList(data.value);
    }
  }

  // On select final destination country, final destination points are filtered
  // if user click "x" button to clear che selection, data will be null
  // also Import/Export Restrictions are related to final destination country
  onSelectFinalDestinationCountry(data) {
    this.props.clearDeliveryPoint();
    this.props.deliveryPointListReset();
    if (data && data.value) {
      this.props.deliveryPointList(data.value);
      this.props.dischargePointDefaultList(data.value);
      this.props.ipfDetailImportExportRestrictionList(data.value);
    }
  }

  // On select GCMF zone, GCMF subzones are filtered
  // if user click "x" button to clear che selection, data will be null
  // if there is only a subzone, it will be automatically selected
  onSelectGcmfZone(data) {
    this.props.clearGgmfSubzone();
    this.props.gcmfSubzoneListReset();
    if (data && data.value) {
      this.props.gcmfSubzoneList(data.value).then(gcmfs => {
        if (gcmfs.count && gcmfs.count === 1) {
          this.props.changeGgmfSubzone({
            value: gcmfs.results[0].id,
            label: gcmfs.results[0].name,
            data: gcmfs.results[0]
          });
        }
      });
    }
  }

  // Getting and adapting grant list
  // user has to insert at lest 4 characters different from zero starting from left
  grants(value) {
    const filter = value.replace(/^[0]+/g, "");
    const { grantList } = this.props;
    if (filter.length > 3) {
      return grantList(filter).then(data =>
        data.results.map(result => ({ value: result.code, label: generateGrantLabel(result), data: result }))
      );
    } else {
      return Promise.resolve(undefined);
    }
  }

  // Getting and adapting discharge point list
  dischargePoints(value) {
    return this.props
      .dischargePointList(value)
      .then(data => data.results.map(result => ({ value: result.location_id, label: result.name })));
  }

  // Filter commodity prices starting from a given page and selected commodity in criteria tab
  filterCommodityPrices(commodity, destination, isGcmf) {
    this.props.ipfDetailCommodityPriceList(commodity, destination, isGcmf);
  }

  // Check if destination point date is greater than discharge port date and it is in future
  isOutsideRangeDestinationPointDate(date, currentDate = new Date()) {
    return (
      date < moment(currentDate).startOf("day") || date <= moment(this.props.selectedDischargePortDate).startOf("day")
    );
  }

  // On change discharge port date, destination point have to be cleared
  // if it is before or equal to discharge port date
  // the destination point data, if not set,
  // have to be set as discharge port date plus one day
  onChangeDischargePortDateStandard(date) {
    if (
      date &&
      (isEmpty(this.props.selectedDestinationPointDate) ||
        moment(date) >= moment(this.props.selectedDestinationPointDate))
    ) {
      this.props.changeDestinationPointDateStandard(moment(date).add(1, "days"));
    }
  }

  // On change discharge port date, destination point have to be cleared
  // if it is before or equal to discharge port date
  // the destination point data, if not set,
  // have to be set as discharge port date plus one day
  onChangeDischargePortDateGcmf(date) {
    if (
      date &&
      (isEmpty(this.props.selectedDestinationPointDate) ||
        moment(date) >= moment(this.props.selectedDestinationPointDate))
    ) {
      this.props.changeDestinationPointDateGcmf(moment(date).add(1, "days"));
    }
  }

  // On change discharge port, destination point should be changed
  // if it is empty and if is included in final destination points list
  onChangeDischargePort(data) {
    if (
      data &&
      isEmpty(this.props.selectedDestinationPoint) &&
      this.props.deliveryPoints.find(deliveryPoint => deliveryPoint.value === data.value)
    ) {
      this.props.changeDestinationPoint(data);
    }
  }

  // A method used to navigate to a step and scroll the page to a given position
  changeCurrentTab(tab, scroll = true) {
    this.props.ipfDetailCurrentTab(tab);
    if (scroll) {
      window.scrollTo(0, 180);
    }
  }

  render() {
    const {
      currentTab,
      countries,
      specificCommodities,
      wbs,
      errorMessage,
      projects,
      errorsCriteriaStandardTab,
      errorsCriteriaGcmfTab,
      ipfDetailCriteriaStandardFormValues,
      ipfDetailCriteriaGcmfFormValues,
      commodityPrices,
      isFetchingCommodityPrices,
      errorMessageCommodityPrices,
      isFetchingCountries,
      isFetchingWbs,
      isFetchingSpecificCommodities,
      isFetchingProjects,
      errorsDestinationRtaStandard,
      errorsDestinationRtaGcmf,
      errorsCompareSources,
      deliveryPoints,
      isFetchingDeliveryPoints,
      dischargePointDefaults,
      purchaseOptions,
      isLoadingPurchaseOptions,
      errorMessagePurchaseOptions,
      errorsSelectSource,
      ippComparison,
      commodityCounting,
      errorMessageJustification,
      selectedPurchaseOptions,
      ipfDetailJustificationFormValues,
      isLoadingIpfAdd,
      errorMessageIpfAdd,
      isSuccessIpfAddModalOpen,
      warningMessageCommodityPrice,
      importExportRestriction,
      isGcmf,
      gcmfZones,
      gcmfSubzones,
      isFetchingGcmfZones,
      isFetchingGcmfSubzones,
      isOnEdit,
      ipf,
      ipfDetailDestinationRtaStandardFormValues,
      ipfDetailDestinationRtaGcmfFormValues,
      initialFormValuesCriteriaStandard,
      initialFormValuesDestinationRtaStandard,
      initialFormValuesCompareSource,
      initialFormSelectSource,
      initialFormValuesJustification,
      files,
      isLoadingIpfUpdate,
      errorMessageIpfUpdate,
      initialFormValuesCriteriaGcmf,
      initialFormValuesDestinationRtaGcmf,
      initialFormValuesReviewCosts,
      clearers,
      isFetchingClearers,
      errorsSummaryStandardTab,
      initialFormValuesSummaryStandard,
      initialFormValuesSummaryGcmf,
      isLoadingIpfDetailIpfAttachmentAddMultiple,
      errorsReviewCostsAndTimes,
      calculatedPurchaseOptions
    } = this.props;
    const selectedData = generateSelectedData(
      isGcmf,
      ipfDetailCriteriaGcmfFormValues,
      ipfDetailCriteriaStandardFormValues,
      ipfDetailDestinationRtaStandardFormValues,
      ipfDetailDestinationRtaGcmfFormValues,
      importExportRestriction,
      initialFormValuesCriteriaStandard
    );
    let options = purchaseOptions;
    const modalSuccessText = currentTab < 6? "IPF has been saved" : "IPF has been submitted";

    return (
      <PageStandard title="IPF Detail">
        <Grid>
          <Row>
            <Col md={3}>
              <StepNavigation
                active={currentTab}
                items={[
                  { label: "Criteria", page: 0, status: getTabStatus(currentTab, 0) },
                  { label: "Destination and RTA", page: 1, status: getTabStatus(currentTab, 1) },
                  { label: "Compare sources", page: 2, status: getTabStatus(currentTab, 2) },
                  { label: "Review costs and times", page: 3, status: getTabStatus(currentTab, 3) },
                  { label: "Select source", page: 4, status: getTabStatus(currentTab, 4) },
                  { label: "Justification", page: 5, status: getTabStatus(currentTab, 5) },
                  { label: "Summary", page: 6, status: getTabStatus(currentTab, 6) }
                ]}
              />
            </Col>
            <Col md={9}>
              <CriteriaStandard
                isVisible={currentTab === 0 && !isGcmf}
                countries={countries}
                specificCommodities={specificCommodities}
                wbs={wbs}
                errorMessage={errorMessage}
                onClickNext={this.onClickNextOnCriteriaStandard}
                onClickDraft={this.onClickDraftOnCriteriaStandard}
                projects={projects}
                onSelectProjectCountry={this.onSelectProjectCountry}
                onSelectProject={this.onSelectProject}
                onSelectFinalDestinationCountry={this.onSelectFinalDestinationCountry}
                errorObject={errorsCriteriaStandardTab}
                grants={this.grants}
                loadingCountries={isFetchingCountries}
                loadingWbs={isFetchingWbs}
                loadingSpecificCommodities={isFetchingSpecificCommodities}
                loadingProjects={isFetchingProjects}
                onSelectCommodityPrice={this.onSelectCommodityPrice}
                warningMessageCommodityPrice={warningMessageCommodityPrice}
                isOnEdit={isOnEdit}
                initialValues={initialFormValuesCriteriaStandard}
              />
              <CriteriaGcmf
                isVisible={currentTab === 0 && isGcmf}
                specificCommodities={specificCommodities}
                gcmfZones={gcmfZones}
                gcmfSubzones={gcmfSubzones}
                errorMessage={errorMessage}
                onClickNext={this.onClickNextOnCriteriaGcmf}
                onClickDraft={this.onClickDraftOnCriteriaGcmf}
                errorObject={errorsCriteriaGcmfTab}
                loadingGcmfZones={isFetchingGcmfZones}
                loadingGcmfSubones={isFetchingGcmfSubzones}
                loadingSpecificCommodities={isFetchingSpecificCommodities}
                warningMessageCommodityPrice={warningMessageCommodityPrice}
                onSelectCommodityPrice={this.onSelectCommodityPrice}
                onSelectGcmfZone={this.onSelectGcmfZone}
                isOnEdit={isOnEdit}
                initialValues={initialFormValuesCriteriaGcmf}
              />
              <DestinationAndRtaStandard
                isVisible={currentTab === 1 && !isGcmf}
                onClickNext={this.onClickNextOnDestinationAndRtaStandard}
                onClickPrevious={this.onClickPreviousOnDestinationAndRtaStandard}
                onClickDraft={this.onClickDraftOnDestinationAndRtaStandard}
                errorObject={errorsDestinationRtaStandard}
                deliveryPoints={deliveryPoints}
                dischargePoints={this.dischargePoints}
                isOutsideRangeDestinationPointDate={this.isOutsideRangeDestinationPointDate}
                onChangeDischargePortDate={this.onChangeDischargePortDateStandard}
                loadingDeliveryPoints={isFetchingDeliveryPoints}
                dischargePointDefaults={dischargePointDefaults}
                onChangeDischargePort={this.onChangeDischargePort}
                isOnEdit={isOnEdit}
                initialValues={initialFormValuesDestinationRtaStandard}
              />
              <DestinationAndRtaGcmf
                isVisible={currentTab === 1 && isGcmf}
                onClickNext={this.onClickNextOnDestinationAndRtaGcmf}
                onClickPrevious={this.onClickPreviousOnDestinationAndRtaGcmf}
                onClickDraft={this.onClickDraftOnDestinationAndRtaGcmf}
                errorObject={errorsDestinationRtaGcmf}
                dischargePoints={this.dischargePoints}
                isOutsideRangeDestinationPointDate={this.isOutsideRangeDestinationPointDate}
                onChangeDischargePortDate={this.onChangeDischargePortDateGcmf}
                isOnEdit={isOnEdit}
                initialValues={initialFormValuesDestinationRtaGcmf}
              />
              <CompareSources
                isVisible={currentTab === 2}
                onClickNext={this.onClickNextOnCompareSources}
                onClickPrevious={this.onClickPreviousOnCompareSources}
                onClickDraft={this.onClickDraftOnCompareSources}
                selectedData={selectedData}
                loadingCommodityPrices={isFetchingCommodityPrices}
                errorMessageCommodityPrices={errorMessageCommodityPrices}
                commodityPrices={commodityPrices}
                errorMessageCompareSources={errorsCompareSources}
                isOnEdit={isOnEdit}
                initialValues={initialFormValuesCompareSource}
              />
              <ReviewCostsAndTimes
                isVisible={currentTab === 3}
                onClickNext={this.onClickNextOnReviewCostsAndTimes}
                onClickPrevious={this.onClickPreviousOnReviewCostsAndTimes}
                onClickDraft={this.onClickDraftOnReviewCostsAndTimes}
                selectedData={selectedData}
                purchaseOptions={options}
                initialValues={initialFormValuesReviewCosts}
                loadingPurchaseOptions={isLoadingPurchaseOptions}
                errorMessagePurchaseOptions={errorMessagePurchaseOptions}
                errorObject={errorsReviewCostsAndTimes}
              />
              <SelectSource
                isVisible={currentTab === 4}
                onClickNext={this.onClickNextOnSelectSource}
                onClickPrevious={this.onClickPreviousOnSelectSource}
                onClickDraft={this.onClickDraftOnSelectSource}
                selectedData={selectedData}
                purchaseOptions={calculatedPurchaseOptions}
                ipf={ipf}
                errorsSelectSource={errorsSelectSource}
                initialValues={initialFormSelectSource}
              />
              <Justification
                isVisible={currentTab === 5}
                onClickNext={this.onClickNextOnJustification}
                onClickPrevious={this.onClickPreviousOnJustification}
                onClickDraft={this.onClickDraftOnJustification}
                ippComparison={ippComparison}
                commodityCounting ={commodityCounting}
                errorMessageJustification={errorMessageJustification}
                isOnEdit={isOnEdit}
                initialValues={initialFormValuesJustification}
                files={files[0]}
              />
              <SummaryStandard
                isVisible={currentTab === 6 && !isGcmf}
                onClickNext={this.onClickNextOnSummaryStandard}
                onClickPrevious={this.onClickPreviousOnSummaryStandard}
                selectedData={selectedData}
                selectedPurchaseOptions={selectedPurchaseOptions}
                justificationsAndAttachments={ipfDetailJustificationFormValues}
                errorMessage={errorMessageIpfAdd || errorMessageIpfUpdate}
                clearers={clearers}
                ipf={ipf}
                loadingClearers={isFetchingClearers}
                errorObject={errorsSummaryStandardTab}
                initialValues={initialFormValuesSummaryStandard}
              />
              <SummaryGcmf
                isVisible={currentTab === 6 && isGcmf}
                onClickNext={this.onClickNextOnSummaryGcmf}
                onClickPrevious={this.onClickPreviousOnSummaryGcmf}
                selectedData={selectedData}
                ipf={ipf}
                selectedPurchaseOptions={selectedPurchaseOptions}
                justificationsAndAttachments={ipfDetailJustificationFormValues}
                initialValues={initialFormValuesSummaryGcmf}
                errorMessage={errorMessageIpfAdd || errorMessageIpfUpdate}
              />
            </Col>
          </Row>
        </Grid>
        <ModalSuccess
          isOpen={isSuccessIpfAddModalOpen}
          onValidate={this.onValidateSuccessIpfAddModal}
          text={modalSuccessText}
        />
        <Loading isVisible={isLoadingIpfAdd || isLoadingIpfUpdate}>Saving IPF...</Loading>
        <Loading isVisible={isLoadingIpfDetailIpfAttachmentAddMultiple}>Saving attachments...</Loading>
      </PageStandard>
    );
  }
}

// propTypes for the IPFDetail component
IPFDetail.propTypes = {
  currentTab: PropTypes.number.isRequired,
  ipfDetailCurrentTab: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  countryList: PropTypes.func.isRequired,
  wbsList: PropTypes.func.isRequired,
  specificCommodityList: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired
    })
  ),
  wbs: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.any
    })
  ),
  specificCommodities: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired
    })
  ),
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired
    })
  ),
  ipfDetailCriteriaStandardErrors: PropTypes.func.isRequired,
  clearProject: PropTypes.func.isRequired,
  clearWbs: PropTypes.func.isRequired,
  wbsListReset: PropTypes.func.isRequired,
  projectList: PropTypes.func.isRequired,
  ipfDetailCriteriaStandardFormValues: PropTypes.any,
  errorsCriteriaStandardTab: PropTypes.object.isRequired,
  grantList: PropTypes.func.isRequired,
  ipfDetailCommodityPriceList: PropTypes.func.isRequired,
  selectedCommodity: PropTypes.string.isRequired,
  commodityPrices: PropTypes.array.isRequired,
  isFetchingCommodityPrices: PropTypes.bool.isRequired,
  errorMessageCommodityPrices: PropTypes.string.isRequired,
  isFetchingCountries: PropTypes.bool.isRequired,
  isFetchingWbs: PropTypes.bool.isRequired,
  isFetchingSpecificCommodities: PropTypes.bool.isRequired,
  isFetchingProjects: PropTypes.bool.isRequired,
  ipfDetailDestinationRtaStandardFormValues: PropTypes.object,
  ipfDetailDestinationRtaStandardErrors: PropTypes.func.isRequired,
  errorsDestinationRtaStandard: PropTypes.object.isRequired,
  dischargePointList: PropTypes.func.isRequired,
  deliveryPointList: PropTypes.func.isRequired,
  selectedDischargePortDate: PropTypes.object.isRequired,
  selectedDestinationPointDate: PropTypes.object.isRequired,
  changeFinalDestinationCounty: PropTypes.func.isRequired,
  errorsCompareSources: PropTypes.string.isRequired,
  ipfDetailCompereSourcesErrors: PropTypes.func.isRequired,
  ipfDetailCompareSourcesFormValues: PropTypes.any,
  projectListReset: PropTypes.func.isRequired,
  clearDeliveryPoint: PropTypes.func.isRequired,
  deliveryPointListReset: PropTypes.func.isRequired,
  deliveryPoints: PropTypes.array.isRequired,
  isFetchingDeliveryPoints: PropTypes.bool.isRequired,
  changeDestinationPointDateStandard: PropTypes.func.isRequired,
  dischargePointDefaultList: PropTypes.func.isRequired,
  dischargePointDefaults: PropTypes.array.isRequired,
  selectedDestinationPoint: PropTypes.object.isRequired,
  changeDestinationPoint: PropTypes.func.isRequired,
  ipfDetailSelectSourceErrors: PropTypes.func.isRequired,
  ipfDetailSelectSourceFormValues: PropTypes.any,
  selectedQuantity: PropTypes.string.isRequired,
  selectedDischargePort: PropTypes.object.isRequired,
  ipfDetailCommodityPriceOptions: PropTypes.func.isRequired,
  ipfDetailCommodityPriceOptionsReset: PropTypes.func.isRequired,
  purchaseOptions: PropTypes.array.isRequired,
  errorMessagePurchaseOptions: PropTypes.string.isRequired,
  errorsSelectSource: PropTypes.string.isRequired,
  isLoadingPurchaseOptions: PropTypes.bool.isRequired,
  ipfDetailJustificationErrors: PropTypes.func.isRequired,
  ipfDetailJustificationFormValues: PropTypes.any,
  ippComparison: PropTypes.shape({
    isAboveIpp: PropTypes.bool,
    amountAboveIpp: PropTypes.string,
    percentageAboveIpp: PropTypes.string
  }),
  commodityCounting : PropTypes.bool,
  errorMessageJustification: PropTypes.string.isRequired,
  selectedPurchaseOptions: PropTypes.array.isRequired,
  ipfAddReset: PropTypes.func.isRequired,
  showSuccessIpfAddModal: PropTypes.func.isRequired,
  hideSuccessIpfAddModal: PropTypes.func.isRequired,
  history: PropTypes.any.isRequired,
  ipfAdd: PropTypes.func.isRequired,
  errorMessageIpfAdd: PropTypes.string.isRequired,
  isSuccessIpfAddModalOpen: PropTypes.bool.isRequired,
  isLoadingIpfAdd: PropTypes.bool.isRequired,
  warningMessageCommodityPrice: PropTypes.string.isRequired,
  ipfDetailCommodityPriceListReset: PropTypes.func.isRequired,
  ipfDetailImportExportRestrictionListReset: PropTypes.func.isRequired,
  ipfDetailImportExportRestrictionList: PropTypes.func.isRequired,
  importExportRestriction: PropTypes.object.isRequired,
  userDetail: PropTypes.func.isRequired,
  currentUser: PropTypes.any,
  isGcmf: PropTypes.bool.isRequired,
  gcmfZoneList: PropTypes.func.isRequired,
  clearGgmfSubzone: PropTypes.func.isRequired,
  gcmfSubzoneListReset: PropTypes.func.isRequired,
  gcmfSubzoneList: PropTypes.func.isRequired,
  gcmfZones: PropTypes.array.isRequired,
  gcmfSubzones: PropTypes.array.isRequired,
  isFetchingGcmfZones: PropTypes.bool.isRequired,
  isFetchingGcmfSubzones: PropTypes.bool.isRequired,
  ipfDetailCriteriaGcmfErrors: PropTypes.func.isRequired,
  ipfDetailCriteriaGcmfFormValues: PropTypes.any,
  errorsCriteriaGcmfTab: PropTypes.object.isRequired,
  ipfDetailDestinationRtaGcmfFormValues: PropTypes.object,
  ipfDetailDestinationRtaGcmfErrors: PropTypes.func.isRequired,
  changeDestinationPointDateGcmf: PropTypes.func.isRequired,
  errorsDestinationRtaGcmf: PropTypes.object.isRequired,
  selectedGcmfSubzone: PropTypes.object.isRequired,
  ipfDetailCommodityPriceCount: PropTypes.func.isRequired,
  ipfDetailCommodityPriceCountReset: PropTypes.func.isRequired,
  id: PropTypes.any,
  ipf: PropTypes.object,
  isOnEdit: PropTypes.bool.isRequired,
  ipfDetailIpfDetail: PropTypes.func.isRequired,
  dischargePointDefaultListReset: PropTypes.func.isRequired,
  ipfUpdateReset: PropTypes.func.isRequired,
  isFetchingDischargePointDefaults: PropTypes.bool.isRequired,
  isFetchingImportExportRestrictions: PropTypes.bool.isRequired,
  ipfUpdate: PropTypes.func.isRequired,
  isLoadingIpfUpdate: PropTypes.bool.isRequired,
  errorMessageIpfUpdate: PropTypes.string.isRequired,
  initialFormValuesCriteriaGcmf: PropTypes.object,
  initialFormValuesCriteriaStandard: PropTypes.object,
  initialFormValuesDestinationRtaStandard: PropTypes.object,
  initialFormValuesCompareSource: PropTypes.object,
  initialFormSelectSource: PropTypes.object,
  initialFormValuesJustification: PropTypes.object,
  files: PropTypes.array,
  initialFormValuesDestinationRtaGcmf: PropTypes.object,
  initialFormValuesReviewCosts: PropTypes.object,
  resetJustificationsForm: PropTypes.func.isRequired,
  resetSelectSourceForm: PropTypes.func.isRequired,
  changeGgmfSubzone: PropTypes.func.isRequired,
  clearerList: PropTypes.func.isRequired,
  ipfDetailSummaryStandardFormValues: PropTypes.any,
  ipfDetailSummaryGcmfFormValues: PropTypes.any,
  ipfDetailSummaryStandardErrors: PropTypes.func.isRequired,
  clearers: PropTypes.array.isRequired,
  isFetchingClearers: PropTypes.bool.isRequired,
  errorsSummaryStandardTab: PropTypes.object.isRequired,
  initialFormValuesSummaryStandard: PropTypes.object,
  initialFormValuesSummaryGcmf: PropTypes.object,
  ipfDetailIpfAttachmentAddMultiple: PropTypes.func.isRequired,
  ipfDetailIpfAttachmentGet: PropTypes.func.isRequired,
  isLoadingIpfDetailIpfAttachmentAddMultiple: PropTypes.bool.isRequired,
  ipfDetailReviewCostsAndTimesFormFields: PropTypes.object,
  ipfDetailReviewCostsAndTimesFormValues: PropTypes.object,
  calculatedPurchaseOptions: PropTypes.array.isRequired,
  errorsReviewCostsAndTimes: PropTypes.object.isRequired,
  ipfDetailReviewCostsAndTimesErrors: PropTypes.func.isRequired,
  ipfDetailIpfDetailReset: PropTypes.func.isRequired
};

// defaultProps for the IPFDetail component
IPFDetail.defaultProps = {
  ipfDetailCriteriaStandardFormValues: undefined,
  ipfDetailCriteriaGcmfFormValues: undefined,
  ipfDetailDestinationRtaStandardFormValues: undefined,
  ipfDetailDestinationRtaGcmfFormValues: undefined,
  ipfDetailCompareSourcesFormValues: undefined,
  ipfDetailSelectSourceFormValues: undefined,
  ipfDetailJustificationFormValues: undefined,
  currentUser: undefined,
  id: undefined,
  ipf: undefined,
  initialFormValuesCriteriaGcmf: undefined,
  initialFormValuesCriteriaStandard: undefined,
  initialFormValuesDestinationRtaStandard: undefined,
  initialFormValuesCompareSource: undefined,
  initialFormSelectSource: undefined,
  initialFormValuesJustification: undefined,
  files: [],
  initialFormValuesDestinationRtaGcmf: undefined,
  initialFormValuesReviewCosts: undefined,
  ipfDetailSummaryStandardFormValues: undefined,
  ipfDetailSummaryGcmfFormValues: undefined,
  initialFormValuesSummaryStandard: undefined,
  initialFormValuesSummaryGcmf: undefined,
  ipfDetailReviewCostsAndTimesFormFields: undefined,
  ipfDetailReviewCostsAndTimesFormValues: undefined,
  ippComparison: {
    isAboveIpp: false,
    amountAboveIpp: "",
    percentageAboveIpp: ""
  }
};

// Starting from the redux state it gets data related to specific commodity list
export const mapStateToProps = (state, ownProps) => {
  const {
    userDetail,
    form,
    ipfDetailIpfDetail,
    countryList,
    wbsList,
    specificCommodityList,
    deliveryPointList,
    grantList,
    gcmfZoneList,
    gcmfSubzoneList,
    projectList,
    dischargePointDefaultList,
    ipfDetailImportExportRestrictionList,
    clearerList,
    ipfDetailCriteriaStandardErrors,
    ipfDetailCriteriaGcmfErrors,
    ipfDetailDestinationRtaStandardErrors,
    ipfDetailDestinationRtaGcmfErrors,
    ipfDetailCompereSourcesErrors,
    ipfDetailSelectSourceErrors,
    ipfDetailSummaryStandardErrors,
    ipfDetailReviewCostsAndTimesErrors,
    ipfDetailCommodityPriceCount,
    ipfDetailCommodityPriceList,
    ipfDetailCommodityPriceOptions,
    ipfAdd,
    ipfUpdate,
    ipfDetailIpfAttachmentAddMultiple,
    ipfDetailIpfAttachmentGet,
    successIpfAddModal,
    ipfDetailCurrentTab,
    ipfDetailJustificationErrors
  } = state;
  const {
    ipfDetailCriteriaStandard,
    ipfDetailReviewCostsAndTimes,
    ipfDetailCriteriaGcmf,
    ipfDetailSelectSource,
    ipfDetailDestinationRtaGcmf,
    ipfDetailDestinationRtaStandard,
    ipfDetailCompareSources,
    ipfDetailSummaryStandard,
    ipfDetailSummaryGcmf,
    ipfDetailJustification
  } = form;

  // Define if user is on edit based on URL parameters
  const isOnEdit = ownProps.match.params.id !== undefined;
  // Current user management and IPF type (GCMF or Standard)
  // if user has both permission to create a GCMF IPF and Standard IPF
  // he will create a Standard IPF
  // (GCMF permission is less important than Standard permission)
  // on edit is GCMF is decided by a flag
  const currentUser = (userDetail && !isEmpty(userDetail.data) && userDetail.data) || undefined;
  const isGcmf =
    (isOnEdit && ((!isEmpty(ipfDetailIpfDetail.data) && ipfDetailIpfDetail.data.is_gcmf) || false)) ||
    (!isOnEdit &&
      ((currentUser &&
        !hasPermission(userDetail.data, "ipf.add_purchaserequest") &&
        hasPermission(userDetail.data, "ipf.create_gcmf")) ||
        false));
  // List of times and cost entered by the user
  const ipfDetailReviewCostsAndTimesFormValues =
    (ipfDetailReviewCostsAndTimes && ipfDetailReviewCostsAndTimes.values) || {};
  // Selected quantity
  const selectedQuantity =
    (ipfDetailCriteriaStandard &&
      ipfDetailCriteriaStandard.values &&
      ipfDetailCriteriaStandard.values.quantity &&
      clearNumber(ipfDetailCriteriaStandard.values.quantity)) ||
    (ipfDetailCriteriaGcmf &&
      ipfDetailCriteriaGcmf.values &&
      ipfDetailCriteriaGcmf.values.quantity &&
      clearNumber(ipfDetailCriteriaGcmf.values.quantity)) ||
    "0";
  // Purchase options management
  const purchaseOptions =
    (!isEmpty(ipfDetailCommodityPriceOptions.data) &&
      adaptPurchaseOptions(ipfDetailCommodityPriceOptions.data.options)) ||
    [];
  // Purchase options management
  const calculatedPurchaseOptions =
    (!isEmpty(purchaseOptions) &&
      !isEmpty(ipfDetailReviewCostsAndTimesFormValues) &&
      calculatePurchaseOptions(purchaseOptions, ipfDetailReviewCostsAndTimesFormValues, selectedQuantity)) ||
    [];
  // pass selected options or already saved options that we get from server
  const optionsForComparison = calculatedPurchaseOptions.length > 0? calculatedPurchaseOptions
    : !!ipfDetailIpfDetail && !!ipfDetailIpfDetail.data && !!ipfDetailIpfDetail.data.purchase_options?
      ipfDetailIpfDetail.data.purchase_options : [];
  // pass option that was selected manually or option that was previously saved as preferred
  const selectedOptionForComparison = ipfDetailSelectSource && ipfDetailSelectSource.values &&
  ipfDetailSelectSource.values.options ? Number(ipfDetailSelectSource.values.options)
    : ipfDetailIpfDetail && ipfDetailIpfDetail.data && ipfDetailIpfDetail.data.purchase_options &&
    ipfDetailIpfDetail.data.purchase_options.find((item) => item.preferred) ?
      ipfDetailIpfDetail.data.purchase_options.find((item) => item.preferred).id
      : -1;
  // List of properties related to justification tab,
  // selected source option can be equal to zero, zero is a valid id and have to be considered as valid
  const ippComparison = calculateIppComparison(optionsForComparison, selectedOptionForComparison,
    ipfDetailIpfDetail && ipfDetailIpfDetail.data ? ipfDetailIpfDetail.data : {}
  );

  const commodityCounting = calculateCommodityCounting(optionsForComparison);
  
  const getCriteriaValue = (criteriaData, criteria) => {
    return criteriaData && criteriaData.values && criteriaData.values[criteria];
  };

  const getDestinationRtaCriteria = (criteria, defaultValue) => {
    const gcmfCriteria = getCriteriaValue(ipfDetailDestinationRtaGcmf, criteria);
    const standardCriteria = getCriteriaValue(ipfDetailDestinationRtaStandard, criteria);
    const selectedCriteria = gcmfCriteria || standardCriteria;
    return selectedCriteria || defaultValue;
  };

  const filterCancelled = (array, label) => {
    if (array.length > 0) {
      const arrayFiltered = array.filter(obj => {
        const name = obj[label];
        return !name.toUpperCase().includes("CANCELLED");
      });
      return arrayFiltered;
    }
    return array;
  };

  const filterSelected = (purchaseOption) => {
    if (purchaseOption.hasOwnProperty("tmp_id")) {
      return (
        ipfDetailSelectSource &&
        ipfDetailSelectSource.values &&
        Number(ipfDetailSelectSource.values.options) === purchaseOption.tmp_id
      );
    } else if (ipfDetailIpfDetail.data && purchaseOption.hasOwnProperty("id")) {
      return(
        ipfDetailSelectSource &&
        ipfDetailSelectSource.values &&
        Number(ipfDetailSelectSource.values.options) === purchaseOption.id
      );
    } else {
      return false;
    }
  };

  let selectedSourcesOptions = [];
  if (!isEmpty(calculatedPurchaseOptions)) {
    selectedSourcesOptions = calculatedPurchaseOptions.filter((purchaseOption) => filterSelected(purchaseOption));
  } else if (ipfDetailIpfDetail.data && ipfDetailIpfDetail.data.purchase_options) {
    selectedSourcesOptions = ipfDetailIpfDetail.data.purchase_options.filter(
      purchaseOption => filterSelected(purchaseOption));
  }

  return {
    // Properties of the current on edit IPF
    id: ownProps.match.params.id,
    ipf: ipfDetailIpfDetail.data,
    isOnEdit,
    // Initial form values for each tab (step of wizard)
    // before to decide if it is a GCMF or a standard IPF
    // we have to wait that che user is loaded
    // compare source form have to be reset
    // if final destination point changes
    initialFormValuesCriteriaStandard:
      (currentUser && isOnEdit && !isGcmf && adaptCriteriaStandardInitialFormValues(ipfDetailIpfDetail.data)) ||
      undefined,
    initialFormValuesCriteriaGcmf:
      (currentUser && isOnEdit && isGcmf && adaptCriteriaGcmfInitialFormValues(ipfDetailIpfDetail.data)) || undefined,
    initialFormValuesDestinationRtaStandard:
      (currentUser && isOnEdit && !isGcmf && adaptDestinationRtaStandardInitialFormValues(ipfDetailIpfDetail.data)) ||
      undefined,
    initialFormValuesDestinationRtaGcmf:
      (currentUser && isOnEdit && isGcmf && adaptDestinationRtaGcmfInitialFormValues(ipfDetailIpfDetail.data)) ||
      undefined,
    initialFormValuesReviewCosts:
      (currentUser && isOnEdit && Object.assign(
        {}, ...adaptPurchaseOptionsForReviewCostTimesForm(purchaseOptions, ipfDetailReviewCostsAndTimes))
      ) ||
      undefined,
    initialFormValuesCompareSource:
      (currentUser &&
        isOnEdit &&
        ipfDetailCommodityPriceList.data &&
        ipfDetailCommodityPriceList.data.results &&
        ((isGcmf &&
          !isEmpty(ipfDetailIpfDetail.data) &&
          !isEmpty(ipfDetailDestinationRtaGcmf) &&
          !isEmpty(ipfDetailDestinationRtaGcmf.values) &&
          !isEmpty(ipfDetailDestinationRtaGcmf.values.discharge_port) &&
          ipfDetailIpfDetail.data.discharge_port.id === ipfDetailDestinationRtaGcmf.values.discharge_port.value) ||
          (!isGcmf &&
            !isEmpty(ipfDetailIpfDetail.data) &&
            !isEmpty(ipfDetailDestinationRtaStandard) &&
            !isEmpty(ipfDetailDestinationRtaStandard.values) &&
            !isEmpty(ipfDetailDestinationRtaStandard.values.destination_point) &&
            ipfDetailIpfDetail.data.destination_point.id ===
              ipfDetailDestinationRtaStandard.values.destination_point.value)) &&
        adaptCompareSourceInitialFormValues(ipfDetailIpfDetail.data, ipfDetailCommodityPriceList.data.results)) ||
      undefined,
    initialFormSelectSource: (isOnEdit && adaptSelectSourceInitialFormValues(ipfDetailIpfDetail.data)) || undefined,
    initialFormValuesJustification:
      (currentUser &&
        isOnEdit &&
        initialJustificationBoolean(ippComparison.isAboveIpp, commodityCounting) &&
        adaptJustificationInitialFormValues(ipfDetailIpfDetail.data)) ||
      undefined,
    initialFormValuesSummaryStandard:
      (isOnEdit && adaptSummaryStandardInitialFormValues(ipfDetailIpfDetail.data)) || undefined,
    initialFormValuesSummaryGcmf:
      (isOnEdit && adaptSummaryGcmfInitialFormValues(ipfDetailIpfDetail.data)) || undefined,
    // Related entities props, projects are returned as array if more than one, as objects if only one
    countries: adaptOptions(countryList.data.results, "iso_code", "name"),
    projects: adaptOptions(filterCancelled(projectList.data.results, "short_title"), "code", "short_title"),
    wbs: adaptOptions(filterCancelled(wbsList.data.results, "title"), "code", "code"),
    deliveryPoints: adaptOptions(deliveryPointList.data.results, "location_id", "name"),
    specificCommodities: adaptOptions(specificCommodityList.data.results, "code", "description"),
    dischargePointDefaults: adaptOptions(dischargePointDefaultList.data.results, "location_id", "name"),
    gcmfZones: adaptOptions(gcmfZoneList.data.results, "code", "name"),
    gcmfSubzones: adaptOptions(gcmfSubzoneList.data.results, "id", "name", "", true),
    clearers: adaptOptions(clearerList.data.results, "username", "full_name"),
    // Error messages
    errorMessage:
      ipfDetailIpfDetail.errorMessage ||
      countryList.errorMessage ||
      wbsList.errorMessage ||
      specificCommodityList.errorMessage ||
      deliveryPointList.errorMessage ||
      grantList.errorMessage ||
      gcmfZoneList.errorMessage ||
      gcmfSubzoneList.errorMessage,
    // Loading status
    isFetchingCountries: countryList.isFetching,
    isFetchingWbs: wbsList.isFetching,
    isFetchingSpecificCommodities: specificCommodityList.isFetching,
    isFetchingProjects: projectList.isFetching,
    isFetchingDeliveryPoints: deliveryPointList.isFetching,
    isFetchingGcmfZones: gcmfZoneList.isFetching,
    isFetchingGcmfSubzones: gcmfSubzoneList.isFetching,
    isFetchingDischargePointDefaults: dischargePointDefaultList.isFetching,
    isFetchingImportExportRestrictions: ipfDetailImportExportRestrictionList.isFetching,
    isFetchingClearers: clearerList.isFetching,
    // Commodity warning message
    warningMessageCommodityPrice:
      (ipfDetailCommodityPriceCount.data &&
        ipfDetailCommodityPriceCount.data.count !== undefined &&
        ipfDetailCommodityPriceCount.data.count === 0 &&
        "There are no commodity prices available for selected commodity") ||
      "",
    // Current active tab (step of wizard)
    currentTab: ipfDetailCurrentTab.number,
    // Form values related to each tab (step of wizard)
    // ipfDetailCompareSourcesFormValues only returns rows where the checkbox is selected
    // (if user check a row and then uncheck it, the value returned is false, so we need to exclude false checkbox)
    ipfDetailCriteriaStandardFormValues: (ipfDetailCriteriaStandard && ipfDetailCriteriaStandard.values) || {},
    ipfDetailCriteriaGcmfFormValues: (ipfDetailCriteriaGcmf && ipfDetailCriteriaGcmf.values) || {},
    ipfDetailDestinationRtaStandardFormValues:
      (ipfDetailDestinationRtaStandard && ipfDetailDestinationRtaStandard.values) || {},
    ipfDetailDestinationRtaGcmfFormValues: (ipfDetailDestinationRtaGcmf && ipfDetailDestinationRtaGcmf.values) || {},
    ipfDetailCompareSourcesFormValues:
      (ipfDetailCompareSources &&
        ipfDetailCompareSources.values &&
        Object.keys(ipfDetailCompareSources.values).length &&
        Object.keys(ipfDetailCompareSources.values).filter(key => ipfDetailCompareSources.values[key])) ||
      [],
    ipfDetailSelectSourceFormValues: (ipfDetailSelectSource && ipfDetailSelectSource.values) || {},
    ipfDetailJustificationFormValues: (ipfDetailJustification && ipfDetailJustification.values) || {},
    ipfDetailSummaryStandardFormValues: (ipfDetailSummaryStandard && ipfDetailSummaryStandard.values) || {},
    ipfDetailSummaryGcmfFormValues: (ipfDetailSummaryGcmf && ipfDetailSummaryGcmf.values) || {},
    ipfDetailReviewCostsAndTimesFormValues,
    ipfDetailReviewCostsAndTimesFormFields:
      (ipfDetailReviewCostsAndTimes && ipfDetailReviewCostsAndTimes.registeredFields) || {},
    selectedQuantity,
    selectedCommodity:
      (ipfDetailCriteriaStandard &&
        ipfDetailCriteriaStandard.values &&
        ipfDetailCriteriaStandard.values.commodity &&
        ipfDetailCriteriaStandard.values.commodity.value) ||
      (ipfDetailCriteriaGcmf &&
        ipfDetailCriteriaGcmf.values &&
        ipfDetailCriteriaGcmf.values.commodity &&
        ipfDetailCriteriaGcmf.values.commodity.value) ||
      "",
    selectedDischargePortDate: getDestinationRtaCriteria("rta_discharge_port", {}),
    selectedDestinationPointDate: getDestinationRtaCriteria("rta_destination_point", {}),
    selectedDischargePort: getDestinationRtaCriteria("discharge_port", {}),
    selectedDestinationPoint: getCriteriaValue(ipfDetailDestinationRtaStandard, "destination_point") || {},
    selectedGcmfSubzone: getCriteriaValue(ipfDetailCriteriaGcmf, "gcmf_subzone") || {},
    // Errors to be displayed in each tab
    errorsCriteriaStandardTab: ipfDetailCriteriaStandardErrors.errors,
    errorsCriteriaGcmfTab: ipfDetailCriteriaGcmfErrors.errors,
    errorsDestinationRtaStandard: ipfDetailDestinationRtaStandardErrors.errors,
    errorsDestinationRtaGcmf: ipfDetailDestinationRtaGcmfErrors.errors,
    errorsCompareSources: ipfDetailCompereSourcesErrors.errors.data.message || "",
    errorsSelectSource: ipfDetailSelectSourceErrors.errors.data.message || "",
    errorsSummaryStandardTab: ipfDetailSummaryStandardErrors.errors,
    errorsReviewCostsAndTimes: ipfDetailReviewCostsAndTimesErrors.errors,
    // List of commodities props
    commodityPrices: adaptCommodityPrices(ipfDetailCommodityPriceList.data.results),
    isFetchingCommodityPrices: ipfDetailCommodityPriceList.isFetching,
    errorMessageCommodityPrices: ipfDetailCommodityPriceList.errorMessage,
    // List of properties related to purchase options
    purchaseOptions,
    calculatedPurchaseOptions,
    isLoadingPurchaseOptions: ipfDetailCommodityPriceOptions.isLoading,
    errorMessagePurchaseOptions: isEmpty(ipfDetailCommodityPriceOptions.error) ? "" : "Connection error, try again",
    // List of properties related to justification tab,
    // selected source option can be equal to zero, zero is a valid id and have to be considered as valid
    ippComparison,
    commodityCounting,
    errorMessageJustification: ipfDetailJustificationErrors.errors.data.message || "",
    // List of properties related to summary standard tab (it is just one)
    selectedPurchaseOptions: selectedSourcesOptions,
    // Properties related to IPF creation
    isLoadingIpfAdd: ipfAdd.isLoading,
    errorMessageIpfAdd: isEmpty(ipfAdd.error) ? "" : "Unable to save IPF: " + JSON.stringify(ipfAdd.error.data),
    // Properties related to IPF update
    isLoadingIpfUpdate: ipfUpdate.isLoading,
    errorMessageIpfUpdate: isEmpty(ipfUpdate.error) ? "" : "Unable to save IPF: " + JSON.stringify(ipfUpdate.error.data),
    // Properties related to attachments
    isLoadingIpfDetailIpfAttachmentAddMultiple: ipfDetailIpfAttachmentAddMultiple.isLoading,
    // Properties related to IPF creation success modal
    isSuccessIpfAddModalOpen: successIpfAddModal.isVisible,
    // Import/Export Restrictions related data
    importExportRestriction:
      (ipfDetailImportExportRestrictionList.data &&
        ipfDetailImportExportRestrictionList.data.count &&
        ipfDetailImportExportRestrictionList.data.results[0]) ||
      {},
    // Current user management and IPF type
    currentUser,
    isGcmf
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = dispatch => {
  return {
    ipfDetailCurrentTab: number => dispatch(ipfDetailCurrentTab(number)),
    countryList: () => dispatch(countryList()),
    wbsList: code__startswith => dispatch(wbsList({ code__startswith: `${code__startswith}.` })),
    specificCommodityList: () => dispatch(specificCommodityList()),
    projectList: country__iso_code => dispatch(projectList({ country__iso_code })),
    clearProject: () => dispatch(change("ipfDetailCriteriaStandard", "project", "")),
    clearWbs: () => dispatch(change("ipfDetailCriteriaStandard", "wbs", "")),
    wbsListReset: () => dispatch(wbsListReset()),
    ipfDetailCriteriaStandardErrors: data => dispatch(ipfDetailCriteriaStandardErrors(data)),
    ipfDetailCriteriaGcmfErrors: data => dispatch(ipfDetailCriteriaGcmfErrors(data)),
    ipfDetailDestinationRtaStandardErrors: data => dispatch(ipfDetailDestinationRtaStandardErrors(data)),
    ipfDetailDestinationRtaGcmfErrors: data => dispatch(ipfDetailDestinationRtaGcmfErrors(data)),
    grantList: code => dispatch(grantList({ code })),
    dischargePointList: name => dispatch(dischargePointList({ name })),
    deliveryPointList: country__iso_code => dispatch(deliveryPointList({ country__iso_code })),
    changeFinalDestinationCounty: data => dispatch(change("ipfDetailCriteriaStandard", "recipient_country", data)),
    ipfDetailCommodityPriceList: (commodity, final_destination_loc, is_gcmf) =>
      dispatch(ipfDetailCommodityPriceList({ commodity: [commodity], final_destination_loc, is_gcmf })),
    ipfDetailCommodityPriceCount: commodity => dispatch(ipfDetailCommodityPriceCount({ commodity: [commodity] })),
    ipfDetailCompereSourcesErrors: data => dispatch(ipfDetailCompereSourcesErrors(data)),
    projectListReset: () => dispatch(projectListReset()),
    clearDeliveryPoint: () => dispatch(change("ipfDetailDestinationRtaStandard", "destination_point", "")),
    deliveryPointListReset: () => dispatch(deliveryPointListReset()),
    changeDestinationPointDateStandard: date =>
      dispatch(change("ipfDetailDestinationRtaStandard", "rta_destination_point", date)),
    changeDestinationPointDateGcmf: date =>
      dispatch(change("ipfDetailDestinationRtaGcmf", "rta_destination_point", date)),
    dischargePointDefaultList: valid_for__country__iso_code =>
      dispatch(dischargePointDefaultList({ valid_for__country__iso_code })),
    changeDestinationPoint: data => dispatch(change("ipfDetailDestinationRtaStandard", "destination_point", data)),
    ipfDetailCommodityPriceOptions: data => dispatch(ipfDetailCommodityPriceOptions(data)),
    ipfDetailCommodityPriceOptionsReset:() => dispatch(ipfDetailCommodityPriceOptionsReset()),
    ipfDetailSelectSourceErrors: data => dispatch(ipfDetailSelectSourceErrors(data)),
    ipfDetailJustificationErrors: data => dispatch(ipfDetailJustificationErrors(data)),
    ipfAdd: data => dispatch(ipfAdd(data)),
    ipfAddReset: () => dispatch(ipfAddReset()),
    showSuccessIpfAddModal: () => dispatch(showSuccessIpfAddModal()),
    hideSuccessIpfAddModal: () => dispatch(hideSuccessIpfAddModal()),
    ipfDetailCommodityPriceListReset: () => dispatch(ipfDetailCommodityPriceListReset()),
    ipfDetailCommodityPriceCountReset: () => dispatch(ipfDetailCommodityPriceCountReset()),
    ipfDetailImportExportRestrictionList: country =>
      dispatch(ipfDetailImportExportRestrictionList({ country: [country] })),
    ipfDetailImportExportRestrictionListReset: () => dispatch(ipfDetailImportExportRestrictionListReset()),
    userDetail: () => dispatch(userDetail()),
    gcmfZoneList: () => dispatch(gcmfZoneList()),
    gcmfSubzoneList: gcmf_zone => dispatch(gcmfSubzoneList({ gcmf_zone })),
    clearGgmfSubzone: () => dispatch(change("ipfDetailCriteriaGcmf", "gcmf_subzone", "")),
    changeGgmfSubzone: data => dispatch(change("ipfDetailCriteriaGcmf", "gcmf_subzone", data)),
    gcmfSubzoneListReset: () => dispatch(gcmfSubzoneListReset()),
    ipfDetailIpfDetail: id => dispatch(ipfDetailIpfDetail(id)),
    ipfDetailIpfDetailReset: () => dispatch(ipfDetailIpfDetailReset()),
    dischargePointDefaultListReset: () => dispatch(dischargePointDefaultListReset()),
    ipfUpdate: (id, data) => dispatch(ipfUpdate(id, data)),
    ipfUpdateReset: () => dispatch(ipfUpdateReset()),
    resetJustificationsForm: () => dispatch(reset("ipfDetailJustification")),
    resetSelectSourceForm: () => dispatch(change("ipfDetailSelectSource", "options", null)),
    clearerList: () => dispatch(clearerList()),
    ipfDetailSummaryStandardErrors: data => dispatch(ipfDetailSummaryStandardErrors(data)),
    ipfDetailIpfAttachmentAddMultiple: requests => dispatch(ipfDetailIpfAttachmentAddMultiple(requests)),
    ipfDetailIpfAttachmentGet: requests => dispatch(ipfDetailIpfAttachmentGet(requests)),
    ipfDetailReviewCostsAndTimesErrors: data => dispatch(ipfDetailReviewCostsAndTimesErrors(data))
  };
};

// The form is added to the redux store with a given name
export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(IPFDetail));
