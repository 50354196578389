import React from "react";
import PropTypes from "prop-types";

import style from "./style.scss";

// An upload component
const Upload = ({ name, label, files }) => {
  const fileInputRef = React.useRef(files);
  
  React.useEffect(() => {  
    if (fileInputRef.current) {
      fileInputRef.current.files = files;
    }
  }, [fileInputRef, files]);
  
  return (
  <div className="wfp--form-item">
    <label htmlFor={name} className="wfp--label">{label}</label>
    <div className={style.wrapper}>
      <input
        autoComplete="off"
        multiple
        name={name}
        id={name}
        type={"file"}
        ref={fileInputRef}
      />
    </div>
  </div>)
};

// propTypes for the Upload component
Upload.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  files: PropTypes.object
};

export default Upload;
